import React, { useEffect } from "react";
import About from "./sections/About";
import ContactUs from "./sections/ContactUs";
import EventSchedule from "./sections/EventSchedule";
import FAQ from "./sections/FAQ";
import HeaderSection from "./sections/HeaderSection";
import Judging from "./sections/Judging";
import Prizes from "./sections/Prizes";
import Guests from "./sections/Guests";
import PastJams from "./sections/PastJams";
import PastEvents from "./sections/PastEvents";
import Partners from "./sections/Partners";
import VenueMap from "./sections/VenueMap";

import ShootingStar from "../../components/ShootingStar/ShootingStar";
import Rocket from "../../components/Rocket/Rocket";
import Clouds from "../../components/Clouds/Clouds";

import IMAGES from "../../assets/images";

import "./MainPage.css";
import { Container } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

export default function MainPage() {
    const location = useLocation();
    const navigate = useNavigate();

    // if location is not "/" navigate to "/"
    useEffect(() => {
        if (location.pathname !== "/") {
            navigate("/");
        }

        if (location.hash) {
            const section = document.getElementById(location.hash.slice(1));
            if (section) {
                section.scrollIntoView();
            }
        }
    }, [location, navigate]);

    return (
        <>
            <div>
                <img
                    className="astronaut1"
                    src={IMAGES["astronaut"]}
                    alt="GameCraft Main Logo"
                />
                <img
                    className="astronaut2"
                    src={IMAGES["astronaut"]}
                    alt="GameCraft Main Logo"
                />
            </div>
            <div className="stars-container">
                <ShootingStar delay={0} timing={3} top={"10%"} left={"5vw"} />
                <ShootingStar delay={6} timing={3} top={"100%"} left={"10vw"} />
                <ShootingStar delay={4} timing={3} top={"190%"} left={"30vw"} />
                <ShootingStar delay={2} timing={3} top={"280%"} left={"15vw"} />
                <ShootingStar delay={5} timing={3} top={"130%"} left={"20vw"} />
            </div>
            <div className="rocket-container">
                <Rocket delay={0} timing={10} top={"100vh"} left={"-20vh"} />
            </div>
            <div className="mainpage">
                <Container maxWidth="xl">
                    <HeaderSection />
                    <section style={{ marginTop: "5em" }} id="about-section">
                        <About />
                    </section>
                    <section id="faq-section" style={styles.subSectionSpacing}>
                        <FAQ />
                    </section>
                    <section
                        id="venue-section"
                        style={styles.subSectionSpacing}
                    >
                        <VenueMap />
                    </section>
                    <section
                        id="schedule-section"
                        style={styles.subSectionSpacing}
                    >
                        <EventSchedule />
                    </section>
                    <section
                        id="judging-section"
                        style={styles.subSectionSpacing}
                    >
                        <Judging subSectionStyle={styles.subSectionSpacing} />
                    </section>
                    <section
                        id="past-events-section"
                        style={styles.subSectionSpacing}
                    >
                        <PastEvents />
                    </section>
                    <section
                        id="past-jams-section"
                        style={styles.subSectionSpacing}
                    >
                        <PastJams />
                    </section>
                    <section
                        id="prizes-section"
                        style={styles.subSectionSpacing}
                    >
                        <Prizes />
                    </section>
                </Container>
                {/* PLS DO NOT TOUCH THE CLOUDS, it should be out of the container so it would not be affected by the margins */}
                <Clouds top={"100vh"} left={"-20vh"} />
                <Container maxWidth="xl">
                    <section id="partners-section">
                        <Guests subSectionStyle={styles.subSectionSpacing} />
                        {/* <Partners /> */}
                    </section>
                    <section
                        id="contact-us-section"
                        style={styles.subSectionSpacing}
                    >
                        <ContactUs />
                    </section>
                    <section
                        style={{ ...styles.subSectionSpacing, display: "flex" }}
                    />
                </Container>
            </div>
        </>
    );
}

const styles: { [key: string]: React.CSSProperties } = {
    subSectionSpacing: {
        marginTop: "4em",
    }
};
