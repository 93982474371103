import React, { useState } from "react";
import { Modal, Button, TextField, Box } from "@mui/material";
import { createTeam } from "../../../../../apis/team";
import { useLogin } from "../../../../../hooks/LoginProvider";

type Props = {
    onSuccess: () => void;
    openSnackbar: (severity: "error" | "success", message: string) => void;
    setIsLoading: (isLoading: boolean) => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'black',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: "flex",
    alignContent: "center",
    justifyContent: "space-evenly",
};

const CreateTeam = ({ onSuccess, openSnackbar, setIsLoading }: Props) => {
    const { accessToken, setLogin } = useLogin();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [teamName, setTeamName] = useState<string>("");
    const [helperText, setHelperText] = useState<string>("");

    const onClose = () => {
        setTeamName("");
        setIsOpen(false);
    };

    const setInputText = (text: string) => {
        if (helperText) {
            setHelperText("");
        }
        setTeamName(text);
    }

    const createNewTeam = async () => {
        try {
            if (!teamName) {
                setHelperText("Team name cannot be empty");
                return;
            }
            setIsLoading(true);
            await createTeam({ teamName: teamName }, accessToken, setLogin);
            onSuccess();
        } catch (e) {
            console.log(e);
            openSnackbar("error", String(e));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div style={{ paddingTop: 10 }}> 
            <Button 
                onClick={() => setIsOpen(true)} 
                variant="outlined"
            >
                Create Team
            </Button>
            <Modal open={isOpen} onClose={onClose}>
                <Box sx={style}>
                    <TextField 
                        error={!!helperText}
                        helperText={helperText}
                        label="Team Name"
                        variant="outlined" 
                        value={teamName} 
                        onChange={event => setInputText(event.target.value)} 
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                "& > fieldset": { borderColor: "white", borderRadius: "10px" },
                            },
                        }}
                    />
                    <Button onClick={createNewTeam} variant="outlined">
                        Create
                    </Button>
                </Box>
            </Modal>
        </div>
    );
};

export default React.memo(CreateTeam);
