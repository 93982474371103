import React from 'react';
import TagChip from '../../../../../components/TagChip';
import { Tag } from '../../../../../types/types';

type Props = {
    tags: Tag[];
    setTags: (tags: Tag[]) => void;
};

const EditableTags = ({ tags, setTags }: Props) => {
    const toggleTag = (selected: Tag) => {
        if (tags.includes(selected)) {
            setTags(tags.filter(tag => selected !== tag));
        } else {
            setTags([...tags, selected]);
        }
    };

    return (
        <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
            {Object.values(Tag).map(tag => 
                <div style={{ paddingRight: 5, paddingBottom: 5 }} key={tag}>
                    <TagChip 
                        tag={tag} 
                        selected={tags.includes(tag)}
                        onClick={() => toggleTag(tag)}
                    />
                </div>
            )}
        </div>
    );
};

export default React.memo(EditableTags);
