import React from 'react';
import EditableTeamName from './components/EditableTeamName';
import MemberList from './components/MemberList';
import NoTeam from './components/NoTeam';
import Submission from './components/Submission';
import { Team } from '../../../../types/types';
import { removeMember } from '../../../../apis/team';
import { useLogin } from '../../../../hooks/LoginProvider';

type Props = {
    team?: Team;
    refreshPage: () => Promise<void>;
    openSnackbar: (severity: "error" | "success", message: string) => void;
    setIsLoading: (isLoading: boolean) => void;
};


const TeamInformation = ({ team, refreshPage, openSnackbar, setIsLoading }: Props) => {
    const { accessToken, setLogin, userEmail } = useLogin();

    if (!team) {
        return (
            <div style={{ border: "1px solid white", borderRadius: 10, padding: 16 }}>
                <NoTeam onCreate={refreshPage} setIsLoading={setIsLoading} openSnackbar={openSnackbar} />
            </div>
        );
    }

    const isLeader = team.leaderEmail === userEmail;

    const leaveCurrentTeam = async () => {
        try {
            // leaving is just removing yourself
            setIsLoading(true);
            await removeMember({ teamId: team.teamId, email: userEmail }, accessToken, setLogin);
            await refreshPage();
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", }}>
            <div style={{ border: "1px solid white", borderRadius: 10, padding: 16, marginBottom: 16 }}>
                <EditableTeamName
                    isEditable={isLeader}
                    name={team.teamName}
                    teamId={team.teamId}
                />

                <MemberList
                    members={team.members}
                    leader={team.leaderEmail}
                    isLeader={isLeader}
                    teamId={team.teamId}
                    leaveTeam={leaveCurrentTeam}
                    openSnackbar={openSnackbar}
                />
            </div>


            <div style={{ border: "1px solid white", borderRadius: 10, padding: 16 }}>
                <Submission
                    teamId={team.teamId}
                    submissionLink={team.submissionLink}
                    openSnackbar={openSnackbar}
                />
            </div>
        </div>
    );
};

export default React.memo(TeamInformation);
