import React, { useState } from "react";
import { Button, Box, Popover, IconButton } from "@mui/material";
import { unlinkDiscord } from "../../../../../apis/user";
import { useLogin } from "../../../../../hooks/LoginProvider";
import EditIcon from '@mui/icons-material/Edit';

type Props = {
    resetDiscord: () => void;
};

const UnlinkDiscordButton = ({ resetDiscord }: Props) => {
    const { accessToken, setLogin } = useLogin();
    const [anchorElement, setAnchorElement] = useState<HTMLButtonElement>();
    const removeDiscord = () => unlinkDiscord(accessToken, setLogin).then(resetDiscord);

    return (
        <Box>
            <IconButton
                color="primary"
                onClick={event => setAnchorElement(event.currentTarget)}
            >
                <EditIcon />
            </IconButton>
            <Popover
                open={!!anchorElement}
                anchorEl={anchorElement}
                onClose={() => setAnchorElement(undefined)}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Box sx={{ backgroundColor: "black", padding: 2 }}>
                    Are you sure you want to unlink your discord?
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={() => setAnchorElement(undefined)}>
                            Cancel
                        </Button>
                        <Button color="error" onClick={removeDiscord}>
                            Unlink
                        </Button>
                    </Box>
                </Box>
            </Popover>
        </Box>
    );
};

export default React.memo(UnlinkDiscordButton);
