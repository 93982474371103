import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useLogin } from "../../hooks/LoginProvider";
import { CircularProgress, Typography } from "@mui/material";
import { loginDiscord } from "../../apis/user";
import "./DiscordLogin.css";

const DiscordLogin = () => {
    const { accessToken, setLogin } = useLogin();
    const [searchParams] = useSearchParams();
    const nav = useNavigate();

    useEffect(() => {
        const code = searchParams.get("code");
        if (!code) {
            return;
        }
        const resp = loginDiscord({ code }, accessToken, setLogin);
        resp.finally(() => {
            nav("/dashboard");
        });
    }, [searchParams, accessToken, setLogin, nav]);

    return (
        <div className="discord-login">
            <CircularProgress />
            <Typography>Logging in with Discord...</Typography>
        </div>
    );
};

export default React.memo(DiscordLogin);
