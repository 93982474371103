import React from "react";

import "./ShootingStar.css";

interface Props {
    delay: number;
    timing: number;
    top: string;
    left: string;
}


export default function ShootingStar(props: Props) {
    var animation_string = "shootingStar " + props.timing.toString() + "s ease-in-out " + props.delay.toString() + "s infinite";

    var styles: { [key: string]: React.CSSProperties } = {
        shootingStar: {
            animation: animation_string,
            top: props.top,
            left: props.left,
        },
    };
    

    return (
        <>
            <div className="shooting-star" style={styles.shootingStar}>
                <div className="star-body"></div><div className="shooting-star-tail"></div>
            </div>

        </>
    );
}

