import { createTheme } from "@mui/material/styles";

const mainTheme = createTheme({
    palette: {
        //color=
        primary: {
            //primary
            main: "#FFFFFF", //white
        },
        secondary: {
            //secondary
            main: "#ffad00", //orange
        },
        text: {
            primary: "#FFFFFF", //textPrimary
            secondary: "#ffad00", //textSecondary
            disabled: "#ffad00", //textDisabled
        },
    },
    typography: {
        fontFamily: '"ABeeZee", sans-serif', //default font
        h1: {
            //variant = h1
            fontFamily: '"Patrick Hand SC"', //default font
            fontSize: "5rem",
        },
        h2TitleFont: {
            fontFamily: '"Patrick Hand SC"', //default font
            fontSize: "3rem",
        },
        h2: {
            fontSize: "2rem",
        },
        h3NoBold: {
            fontSize: "1.5rem",
        },
        h3: {
            fontSize: "1.5rem",
            fontWeight: "600",
        },
        h3TitleFont: {
            fontFamily: '"Patrick Hand SC"', //default font
            fontSize: "2.5rem",
        },
        h4: {
            fontSize: " 1.2rem",
            fontWeight: "bold",
        },
        h4TitleFont: {
            fontSize: "2rem",
            fontFamily: '"Patrick Hand SC"',
        },
        h5: {
            fontSize: "1.1rem",
            fontWeight: "bold",
        },
        h5TitleFont: {
            fontSize: "1.7rem",
            lineHeight: 1,
            fontFamily: '"Patrick Hand SC"',
        },
        body1: {
            fontSize: "1.1rem",
        },
        button: {
            fontSize: "1rem",
            textTransform: "none",
            fontWeight: "600",
        },
        caption: {
            fontSize: "1rem",
        },
        subtitle1: {
            fontSize: "0.9rem",
            lineHeight: 1
        },
        subtitle2: {
            fontSize: "0.8rem"
        },
        overline: {},
        link: {
            color: "#32F9ED",
            textDecoration: "underline",
        }
    },
});

declare module "@mui/material/styles/createTypography" {
    interface Typography {
        h2TitleFont: React.CSSProperties;
        h3NoBold: React.CSSProperties;
        h3TitleFont: React.CSSProperties;
        h4TitleFont: React.CSSProperties;
        h5TitleFont: React.CSSProperties;
        link: React.CSSProperties;
    }

    interface TypographyOptions {
        h2TitleFont: React.CSSProperties;
        h3NoBold?: React.CSSProperties;
        h3TitleFont?: React.CSSProperties;
        h4TitleFont?: React.CSSProperties;
        h5TitleFont?: React.CSSProperties;
        link: React.CSSProperties;
    }
}

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        h2TitleFont: true;
        h3NoBold: true;
        h4TitleFont: true;
        h5TitleFont: true;
        link: true;
        h3TitleFont: true;
    }
}

//example: <Typography variant="h1" color="textPrimary">

export default mainTheme;
