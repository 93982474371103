import React from "react";
import { sanitize } from "dompurify";

//https://github.com/cure53/DOMPurify to see options
const defaultOptions = {
    ADD_ATTR: ["target"],
    /**
     * Allow certain sites
     *
     * eg. /^(https):(\/\/docs(?:[^a-zA-Z]|$))|(www\.youtube)/
     * => Allow https://docs or https:www.youtube
     */
    //ALLOWED_URI_REGEXP: /^(https):(\/\/docs(?:[^a-zA-Z]|$))|(www\.youtube)/,
};

interface Props {
    dirtyHtml: string;
    options?: any;
    style?: any;
}

function SanitizeHtml({ dirtyHtml, options, style }: Props) {
    const sanitizeHtml = (): any => ({
        __html: sanitize(dirtyHtml, { ...defaultOptions, ...options }),
    });

    return <div dangerouslySetInnerHTML={sanitizeHtml()} style={style} />;
}

export default React.memo(SanitizeHtml);
