import React, { useState } from "react";
import { Button, Box, Popover } from "@mui/material";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined"

type Props = {
    leaveTeam: () => Promise<void>;
};

const LeaveTeamButton = ({ leaveTeam }: Props) => {
    const [anchorElement, setAnchorElement] = useState<HTMLButtonElement>();

    return (
        <div style={{ padding: 10 }}>
            <Button 
                variant="contained" 
                color="error" 
                startIcon={<LogoutOutlinedIcon />}
                onClick={event => setAnchorElement(event.currentTarget)}
            >
                Leave
            </Button>
            <Popover
                open={!!anchorElement}
                anchorEl={anchorElement}
                onClose={() => setAnchorElement(undefined)}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Box sx={{ backgroundColor: "black", padding: 2 }}>
                    Are you sure you want to leave the team?
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={() => setAnchorElement(undefined)}>
                            Cancel
                        </Button>
                        <Button color="error" onClick={leaveTeam}>
                            Leave
                        </Button>
                    </Box>
                </Box>
            </Popover>
        </div>
    );
};

export default React.memo(LeaveTeamButton);
