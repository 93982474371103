import React from "react";
import { Grid, Typography } from "@mui/material";
import IMAGES from "../../../../assets/images";
import { useEventInfo } from "../../../../hooks/EventInfoProvider";
import { ThemeContext } from "@mui/styled-engine";

import contactInfo from "../../../../dataFiles/ContactInfo.json";


import "./ContactUs.css";

export default function ContactUs() {
  const eventInfo = useEventInfo();
  const theme: any = React.useContext(ThemeContext);

  return (
    <>
      <Grid
        item
        xs={10}
        md={8}
        sx={{
          p: 3,
        }}
      >
        <div className="contact-us">
          <Typography variant="h2TitleFont">Contact Us</Typography>
          <div className="contact-us-body-container">
            <Typography variant="body1">
              If you have any questions, please contact us at
            </Typography>
            <Typography
              variant="body1"
              component="a"
              href={`mailto:${eventInfo?.email}`}
              sx={{
                ...theme.typography.link,
              }}
            >
              {eventInfo?.email}
            </Typography>
          </div>

          <br />
          <br />

          <img className="room" src={IMAGES["room"]} alt="" />

          <br />
          <br />

          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4 text-lg-start">
                Copyright &copy; nus-gdg.github.io 2024
              </div>
              <div className="col-lg-4 my-3 my-lg-0 icons-section">
                <a
                  className="btn btn-dark btn-social mx-2"
                  href={`mailto:${eventInfo?.email}`}
                >
                  <i className="fas fa-envelope"></i>
                </a>
                <a
                  className="btn btn-dark btn-social mx-2"
                  href={contactInfo["discord"]}
                >
                  <i className="fab fa-discord"></i>
                </a>
                <a
                  className="btn btn-dark btn-social mx-2"
                  href={contactInfo["website"]}
                >
                  <i className="fas fa-globe"></i>
                </a>
                <a
                  className="btn btn-dark btn-social mx-2"
                  href={contactInfo["instagram"]}
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  className="btn btn-dark btn-social mx-2"
                  href={contactInfo["itch"]}
                >
                  <i className="fab fa-itch-io"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
}
