import { ProfilePicture } from "../types/types"
import IMAGES from "../assets/images"

const profilePictures = {
  [ProfilePicture.ASTRONAUT]: IMAGES["astronaut"],
  [ProfilePicture.ALIEN_1]: IMAGES["alien1"],
  [ProfilePicture.ALIEN_2]: IMAGES["alien2"],
  [ProfilePicture.ALIEN_3]: IMAGES["alien3"],
};

export default profilePictures;
