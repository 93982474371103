export type User = {
  email: string;
  password: string; // maybe a bad idea to have this as a field in the actual deployment
  name: string;
  isLegalAge: boolean;
  indemnityForm: boolean;
  discordName: string;
  isDiscordVerified: boolean;
  faculty: string;
  course: string;
  yearOfStudy: string; // number?
  profilePicture: ProfilePicture; // enum because pictures are chosen from a predefined set
  tags: Tag[];
  eventsParticipated: number[];
  teamId?: string; // user can have no team
};

export type Team = {
  teamId: string;
  teamName: string;
  members: User[];
  invitees: string[];
  leaderEmail: string;
  submissionLink?: string; // can don't have submission link
};

// expand or rename these enums as needed
export enum Tag {
  PROGRAMMER = "Programmer",
  ARTIST = "Artist",
  DESIGNER = "Designer",
  WRITER = "Writer",
  MUSICIAN = "Musician",
};

export enum ProfilePicture {
  ASTRONAUT = "astronaut",
  ALIEN_1 = "alien1",
  ALIEN_2 = "alien2",
  ALIEN_3 = "alien3",
};

export interface EventDetails {
  eventId: number;
  startTime: string;
  endTime: string;
  eventName: string;
  eventDesc: string;
  passwordHash: string;
}
