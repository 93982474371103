import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Box,
    Slide,
    Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { JudgeDetails } from "../../../../../constants/judgeInfo";

import IMAGES from "../../../../../assets/images";

import CloseIcon from "@mui/icons-material/Close";

interface Props {
    isOpen: boolean;
    onClose: () => void;

    judgeDetails: JudgeDetails;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TalkInfoPopup(props: Props) {
    const judgeInfo = () => {
        return (
            <Box
                sx={[
                    styles.centerContent,
                    { minWidth: "250px", maxWidth: "300px" },
                ]}
            >
                <img
                    src={IMAGES[props.judgeDetails.imgPath]}
                    alt={props.judgeDetails.name}
                    style={styles.img}
                />
                <Box sx={styles.centerContent}>
                    <Typography
                        color="secondary"
                        variant="h4TitleFont"
                        sx={{ textAlign: "center" }}
                    >
                        {props.judgeDetails.name}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{ textAlign: "center" }}
                    >
                        {props.judgeDetails.jobTitle}
                        <br />
                        {props.judgeDetails.company}
                    </Typography>
                </Box>
            </Box>
        );
    };

    return (
        <Dialog
            open={props.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.onClose}
            aria-describedby="info-on-judges"
            sx={styles.background}
            PaperProps={{ style: styles.popupContainer }}
        >
            <DialogTitle
                sx={{ marginBottom: { xs: "1rem", sm: "1rem", md: "0" } }}
            >
                <IconButton
                    aria-label="close"
                    onClick={props.onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: "white",
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={styles.contentContainer}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: { xs: "0", sm: "0", md: "2rem" },
                        marginTop: { xs: "2rem", sm: "2rem", md: "0" },
                    }}
                >
                    <Box
                        sx={[styles.centerContent, { marginBottom: "2.5rem" }]}
                    >
                        <Typography
                            color="secondary"
                            variant="h4TitleFont"
                            sx={{
                                textAlign: "center",
                                lineHeight: "1",
                                marginBottom: "0.5rem",
                            }}
                        >
                            {props.judgeDetails.talk?.title}
                        </Typography>
                        <Typography variant="caption" sx={{ lineHeight: 0.8 }}>
                            {props.judgeDetails.talk?.date} |{" "}
                            {props.judgeDetails.talk?.location}
                        </Typography>
                    </Box>

                    <Typography
                        variant="h5TitleFont"
                        sx={{ marginBottom: "0.5rem" }}
                    >
                        About the talk:
                    </Typography>
                    <Typography variant="body1">
                        {props.judgeDetails.talk?.description}
                    </Typography>
                    <Typography
                        variant="h5TitleFont"
                        sx={{ marginTop: "2rem", marginBottom: "0.5rem" }}
                    >
                        About the speaker:{" "}
                    </Typography>
                    <Typography variant="body1">
                        {props.judgeDetails.description}
                    </Typography>
                </Box>
                {judgeInfo()}
            </DialogContent>
        </Dialog>
    );
}

export default React.memo(TalkInfoPopup);

const styles: { [key: string]: any } = {
    background: {
        backdropFilter: "blur(4px)",
        background: "#08376F64",
    },
    popupContainer: {
        // backdropFilter: "blur(3px)",
        background: "#0c499164",
        maxWidth: "900px",
        width: "100%",
    },

    contentContainer: {
        display: "flex",
        flexDirection: "row",

        flexWrap: { xs: "wrap", sm: "wrap", md: "nowrap" },
        justifyContent: { xs: "center", sm: "center", md: "start" },
        alignItems: { xs: "center", sm: "center", md: "start" },
    },

    centerContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
    },

    img: {
        width: 200,
        height: "auto",
        objectFit: "cover",
    },
};
