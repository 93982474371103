import axios from 'axios'

import { TextField, Typography, Checkbox, Select, MenuItem, FormControl } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { memo, useState, useEffect, useCallback } from "react";
import { passwordStrength } from 'check-password-strength'

import IMAGES from "../../assets/images";

import "./Register.css"
import { useNavigate } from 'react-router-dom';
import { SERVER_LINK } from '../../apis/constant'

import { useLogin } from "../../hooks/LoginProvider";

type Props = {
    willNavigateOnSuccess?: boolean;
}

function Register({ willNavigateOnSuccess = true }: Props) {

    const nav = useNavigate();

    const [isSubmitting, setSubmitting] = useState(false);
    const [aboveEighteen, toggleAboveEighteen] = useState(false);
    const [registerStatus, setRegisterStatus] = useState({ init: false, status: false });
    const [errorMessage, setErrorMessage] = useState("Please fill in all fields.");

    const { accessToken } = useLogin();

    // Auto redirect user to /dashboard if already logged in.
    const init = useCallback(async () => {
        try {
            if (accessToken !== "") {
                nav("/dashboard");
            }
        } catch (err) {
            console.log(err);
        }
    }, [accessToken]);

    // Triggered when accesstToken is changed.
    useEffect(() => {
        init();
    }, [accessToken])

    useEffect(() => {
        init();
    }, [])

    const [formData, setFormData] = useState({
        email: '',
        name: '',
        password: '',
        confirmPassword: '',
        isLegalAge: false,
        consent: false,
        indemnityForm: false,
        faculty: '',
        course: '',
        yearOfStudy: 0,
        tags: [-1]
    });

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        formData.email = formData.email.toLowerCase();

        if (formData.confirmPassword !== formData.password) {
            setErrorMessage("Passwords do not match.");
            setRegisterStatus({ init: true, status: false });
            return
        }

        if (formData.password.length < 8) {
            setErrorMessage("Please ensure that your password is at least 8 characters long.");
            setRegisterStatus({ init: true, status: false });
            return
        }

        let passwordTest = passwordStrength(formData.password);
        if (!(passwordTest.contains.includes("lowercase") && passwordTest.contains.includes("uppercase") && passwordTest.contains.includes("number"))) {
            setErrorMessage("Please include upper/lower case and numbers in your password.");
            setRegisterStatus({ init: true, status: false });
            return
        }

        if (formData.tags[0] === -1) {
            setErrorMessage("Please select a role.");
            setRegisterStatus({ init: true, status: false });
            return
        }

        if (!formData.isLegalAge && !formData.consent) {
            setErrorMessage("Please tick the required checkboxes.");
            setRegisterStatus({ init: true, status: false });
            return
        }

        setSubmitting(true);

        // Register
        axios.post(SERVER_LINK + '/register', formData)
            .then((response: any) => {
                if (response.data.msg === 'OK') {
                    setRegisterStatus({ init: true, status: true });
                    setTimeout(function () {
                        willNavigateOnSuccess && nav("/login");
                    }, 5000);
                }
            }).catch((error: any) => {
                setRegisterStatus({ init: true, status: false });
                if (error.response.status === 405) {
                    setErrorMessage("User already registered.");
                } else if (error.response.status === 429) {
                    setErrorMessage("Too many requests, please try again later.");
                } else {
                    setErrorMessage("Error registering, please try again later.");
                }
                setSubmitting(false);
            })
    }

    return (
        <div id="loginFormBg">
            <form id="registerForm" onSubmit={handleSubmit}>
                <Typography variant="h2">REGISTER</Typography>
                <div className="login">
                    <TextField
                        fullWidth
                        value={formData.name}
                        name="name"
                        onChange={(e) => {
                            setFormData({ ...formData, name: e.target.value });
                        }}
                        required
                        placeholder="Full Name"
                        sx={styles.textField}
                        inputProps={{
                            style: styles.inputText,
                        }}
                    />
                    <TextField
                        fullWidth
                        value={formData.email}
                        name="email"
                        type="email"
                        onChange={(e) => {
                            setFormData({ ...formData, email: e.target.value });
                        }}
                        required
                        placeholder="Email"
                        sx={styles.textField}
                        inputProps={{
                            style: styles.inputText,
                        }}
                    />
                    <TextField
                        fullWidth
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={(e) => {
                            setFormData({
                                ...formData,
                                password: e.target.value,
                            });
                        }}
                        required
                        placeholder="Password"
                        sx={styles.textField}
                        inputProps={{
                            style: styles.inputText,
                        }}
                    />
                    <TextField
                        fullWidth
                        type="password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={(e) => {
                            setFormData({
                                ...formData,
                                confirmPassword: e.target.value,
                            });
                        }}
                        required
                        placeholder="Confirm Password"
                        sx={styles.textField}
                        inputProps={{
                            style: styles.inputText,
                        }}

                    />
                    <FormControl fullWidth>
                        <Select
                            required
                            style={styles.selection}
                            value={formData.tags}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    tags: [Number(e.target.value)],
                                });
                            }}
                        >
                            <MenuItem style={styles.option} disabled value={-1}>
                                -- Role --
                            </MenuItem>
                            <MenuItem style={styles.option} value={0}>
                                Programmer
                            </MenuItem>
                            <MenuItem style={styles.option} value={1}>
                                Artist
                            </MenuItem>
                            <MenuItem style={styles.option} value={2}>
                                Designer
                            </MenuItem>
                            <MenuItem style={styles.option} value={3}>
                                Writer
                            </MenuItem>
                            <MenuItem style={styles.option} value={4}>
                                Musician
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <div className="checkbox_group">
                        <Checkbox
                            name="above18"
                            onChange={(e) => {
                                toggleAboveEighteen(e.target.checked);
                                setFormData({
                                    ...formData,
                                    isLegalAge: e.target.checked,
                                });
                            }}
                        />
                        <span>I am over 18 years old.</span>
                    </div>
                    {!aboveEighteen ? (
                        <div className="checkbox_group">
                            <Checkbox
                                name="above18"
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        consent: e.target.checked,
                                    });
                                }}
                            />
                            <span>
                                <i>If you're not 18, you're are required to get parental consent.</i> 
                                <br/>
                                <br/>
                                I consent to my child/ward* participating in
                                the above activity and accept all legal and
                                other responsibilities connected with the
                                activity. I hereby indemnify and agree to keep
                                the National University of Singapore Games
                                Development Group, its management and organizers
                                of the event fully indemnified against all
                                claims, loss or damage or whatsoever in respect
                                of harm, injury, illness, disability, or death
                                arising from any cause in connection with the
                                activity or my participation therein.
                            </span>
                        </div>
                    ) : (
                        false
                    )}
                    <div
                        className="auth_feedback auth_success"
                        style={
                            registerStatus.init && registerStatus.status
                                ? styles.displayBlock
                                : styles.displayNone
                        }
                    >
                        <h4>
                            Account successfully registered. Please check your
                            email for verification (please check the spam
                            folder).
                        </h4>
                    </div>
                    <div
                        className="auth_feedback auth_failure"
                        style={
                            registerStatus.init && !registerStatus.status
                                ? styles.displayBlock
                                : styles.displayNone
                        }
                    >
                        <h4>{errorMessage}</h4>
                    </div>
                    <LoadingButton
                        fullWidth
                        loading={isSubmitting}
                        variant="contained"
                        type="submit"
                        color="secondary"
                    >
                        Register
                    </LoadingButton>
                    <div className="alienPair">
                        <img src={IMAGES["alien1"]} alt="Alien 1" />
                        <img src={IMAGES["alien3"]} alt="Alien 2" />
                    </div>
                </div>
            </form>
        </div>
    );
}

const styles: { [key: string]: React.CSSProperties | any } = {
    selection: {
        backgroundColor: "#F9FAF2",
        color: "#000000",
        borderRadius: 0,
        marginBottom: "2em",
        fontSize: "1rem",
    },
    option: {
        color: "#000000",
        fontSize: "1rem",
    },
    displayBlock: {
        display: "block",
    },
    displayNone: {
        display: "none",
    },

    textField: {
        "& fieldset": { border: "none" },
        marginBottom: "1em",
    },
    inputText: {
        fontSize: "1rem",
    },
};

export default memo(Register);
