import React, { useState, useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { TextField, Button, IconButton } from "@mui/material";
import { addMember } from '../../../../../apis/team';
import { LoginContext } from '../../../../../hooks/LoginProvider';

type Props = {
    teamId: string;
    openSnackbar: (severity: "error" | "success", message: string) => void;
};

const AddMember = ({ teamId, openSnackbar }: Props) => {
    const { accessToken, setLogin } = useContext(LoginContext);
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const [inputEmail, setInputEmail] = useState<string>("");

    const disableAdding = () => {
        setIsAdding(false);
        setInputEmail("");
    };

    const addEmail = async () => {
        try {
            if (!inputEmail) {
                return;
            }
            await addMember({teamId: teamId, email: inputEmail}, accessToken, setLogin);
            openSnackbar("success", "Invite sent to " + inputEmail);
            setInputEmail("");
        } catch (e) {
            // reason for failure e.g. user already in team
            openSnackbar("error", String(e));
        }
    }

    const emailInput = (
        <>
            <TextField 
                id="team-name" 
                variant="standard"
                value={inputEmail}
                placeholder="Email of new member"
                onChange={event => setInputEmail(event.target.value)}
            />
            <IconButton
                color="primary"
                onClick={addEmail}
                sx={{margin: "auto"}}
            >
                <PersonAddAlt1Icon />
            </IconButton>
            <IconButton
                color="error"
                onClick={disableAdding}
                sx={{margin: "auto"}}
            >
                <CloseIcon />
            </IconButton>
        </>
    );

    const addMemberButton = (
        <Button onClick={() => setIsAdding(true)} variant="outlined">
            Add Member
        </Button>
    );

    return (
        <div style={{ padding: 10 }}>
            {isAdding ? emailInput : addMemberButton}
        </div>
    );  
};

export default React.memo(AddMember);
