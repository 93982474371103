import React from "react";

import "./Rocket.css";
import IMAGES from "../../assets/images";

interface Props {
    delay: number;
    timing: number;
    top: string;
    left: string;
}


export default function Rocket(props: Props) {
    var animation_string = "rocketFlight " + props.timing.toString() + "s linear " + props.delay.toString() + "s infinite";

    var styles: { [key: string]: React.CSSProperties } = {
        shootingStar: {
            animation: animation_string,
            top: props.top,
            left: props.left,
        },
    };
    

    return (
        <>
            <div className="rocket-container" style={styles.shootingStar}>
                <img className="rocket-img" src={IMAGES["rocket"]} alt="" />
            </div>

        </>
    );
}

