import axios from 'axios';

import { TextField, Typography } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { memo, useState, useEffect } from "react";
import { passwordStrength } from 'check-password-strength'

import IMAGES from "../../assets/images";

import "./ResetPassword.css"
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SERVER_LINK } from '../../apis/constant';

type Props = {
    willNavigateOnSuccess?: boolean;
}

function Login({ willNavigateOnSuccess = true }: Props) {

    const nav = useNavigate();

    const [isSubmitting, setSubmitting] = useState(false);
    const [isResetted, setIsResetted] = useState({ success: false, init: false });
    const [resetMessage, setResetMessage] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    const [formData, setFormData] = useState({
        email: '',
        password: '',
        token: ''
    })

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setSubmitting(true);

        axios.post(SERVER_LINK + '/reset-password', formData, { withCredentials: true })
            .then((response: any) => {
                if (response.status === 200) {
                    setIsResetted({ success: true, init: true });
                    setResetMessage('Request successful. Please check your email inbox to reset your password.');
                    setSubmitting(false);
                }
            }).catch((error: any) => {
                if (error.request.status === 404) {
                    setIsResetted({ success: false, init: true });
                    setResetMessage('Request Unsuccessful. Please check that you have entered the correct email.');
                    setSubmitting(false);
                } else {
                    setIsResetted({ success: false, init: true });
                    setResetMessage('Too many requests. Please try again later.');
                    setSubmitting(false);
                }
            })
    }

    const handleReset = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setSubmitting(true);

        if (formData.password.length < 8) {
            setIsResetted({ success: false, init: true });
            setResetMessage("Please ensure that your password is at least 8 characters long.");
            setSubmitting(false);
            return
        }

        let passwordTest = passwordStrength(formData.password);
        if (!(passwordTest.contains.includes("lowercase") && passwordTest.contains.includes("uppercase") && passwordTest.contains.includes("number"))) {
            setIsResetted({ success: false, init: true });
            setResetMessage("Please include upper/lower case and numbers in your password.");
            setSubmitting(false);
            return
        }

        axios.post(SERVER_LINK + '/new-password', formData, { withCredentials: true })
            .then((response: any) => {
                if (response.status === 200) {
                    setIsResetted({ success: true, init: true });
                    setResetMessage('Reset successful. Redirecting to login page.');
                    setTimeout(function () {
                        willNavigateOnSuccess && nav("/login");
                    }, 5000);
                }
            }).catch((error: any) => {
                if (error.request.status === 404) {
                    setIsResetted({ success: false, init: true });
                    setResetMessage('Reset Unsuccessful. Please check that you have the correct reset link.');
                    setSubmitting(false);
                } else {
                    setIsResetted({ success: false, init: true });
                    setResetMessage('Too many requests. Please try again later.');
                    setSubmitting(false);
                }
            })
    }

    useEffect(() => {
        if (searchParams.get('token')) {
            formData.token = searchParams.get('token') || '';
            setIsResetted({ success: true, init: true });
            setResetMessage('Please enter your new password.');
        }

        if (searchParams.get('error')) {
            setIsResetted({ success: false, init: true });
            setResetMessage('Reset token have expired. Please request to reset password again.');
        }
    }, [])

    return (
        <div id="loginFormBg">
            <form id="loginForm" onSubmit={(formData.token === '') ? handleSubmit : handleReset}>
                <Typography variant="h2TitleFont">
                    Reset Password
                </Typography>
                <div className={(isResetted.success) ? 'auth_feedback auth_success' : 'auth_feedback auth_failure'} style={(isResetted.init) ? styles.displayBlock : styles.displayNone}>
                    <h4>{resetMessage}</h4>
                </div>
                <div className="login">
                    {formData.token !== '' ?
                        <>
                            <TextField
                                fullWidth
                                value={formData.password}
                                name='password'
                                type='password'
                                onChange={(e) => { setFormData({ ...formData, password: e.target.value }) }}
                                required
                                placeholder="New Password"
                                sx={{
                                    "& fieldset": { border: 'none' },
                                }}
                            />
                            <LoadingButton
                                fullWidth
                                loading={isSubmitting}
                                variant="contained"
                                type="submit"
                                color="secondary"
                            >
                                Reset Password
                            </LoadingButton>
                        </>
                        :
                        <>
                            <TextField
                                fullWidth
                                value={formData.email}
                                name='email'
                                onChange={(e) => { setFormData({ ...formData, email: e.target.value }) }}
                                required
                                placeholder="Email"
                                sx={{
                                    "& fieldset": { border: 'none' },
                                }}
                            />
                            <LoadingButton
                                fullWidth
                                loading={isSubmitting}
                                variant="contained"
                                type="submit"
                                color="secondary"
                            >
                                Reset Password
                            </LoadingButton>
                        </>
                    }
                </div>
            </form >
            <img
                className="alien2"
                src={IMAGES["alien2"]}
                alt="Alien"
            />
        </div>
    );
}

const styles: { [key: string]: React.CSSProperties } = {
    displayBlock: {
        display: "block"
    },
    displayNone: {
        display: "none"
    },
};

export default memo(Login);