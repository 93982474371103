import React from "react";
import { Box, Typography } from "@mui/material";
import IMAGES from "../../../../assets/images";
import pastJams from "../../../../dataFiles/PastJams.json";
import "./PastJams.css";

export default function PastJams() {
    function linkToGame(gameLink: string) {
        // open link in new tab
        window.open(gameLink, "_blank");
        console.log("You clicked submit.");
    }

    const renderPastJamGame = (pastJam: any, index: number) => {
        return (
            <div
                key={index}
                style={{
                    marginLeft: "25px",
                    marginRight: "25px",
                    marginBottom: "25px",
                }}
            >
                {pastJam.theme && renderGameContent(pastJam)}
            </div>
        );
    };

    const renderGameContent = (pastJam: any) => {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography variant="h4">
                    {pastJam.year} theme: {pastJam.theme}
                </Typography>
                <div
                    className="images-container"
                    onClick={() => linkToGame(pastJam.link)}
                >
                    <img
                        className="jamImg"
                        src={IMAGES[pastJam.imgPath]}
                        alt={pastJam.title}
                        style={{ minWidth: "320px" }}
                    />
                    <img
                        className="alien-img"
                        src={IMAGES[pastJam.alien]}
                        alt={pastJam.title}
                    />
                </div>
                <Box style={styles.jamDescription}>
                    <Typography variant="h5">{pastJam.title}</Typography>
                    <Typography variant="body1">
                        {pastJam.description}
                    </Typography>
                </Box>
            </div>
        );
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Typography variant="h2TitleFont" sx={{ marginBottom: "0.8em" }}>
                Past Jam Winners
            </Typography>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "start",
                }}
            >
                {pastJams.map((pastJam, index) => {
                    return renderPastJamGame(pastJam, index);
                })}
            </div>
        </div>
    );
}

const styles: { [key: string]: React.CSSProperties } = {
    jamItem: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: 10,
    },
    jamDescription: {
        textAlign: "center",
        width: "250px",
        padding: 10,
    },
    jamsContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        margin: 10,

        backgroundColor: "#08376f64",
    },
};
