import { Typography } from "@mui/material";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { useEffect, useState } from "react";

import L from "leaflet";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import "./VenueMap.css";
import "leaflet/dist/leaflet.css";
import venueInfo from "../../../../dataFiles/Venue.json";

const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [12.5, 41],
    popupAnchor: [0, -50],
});

interface VenueInfo {
    location: number[];
    name: String;
    days: number[];
}

L.Marker.prototype.options.icon = DefaultIcon;

export default function VenueMap() {
    const [eventMidCoord, setMidCoord] = useState([0, 0]);

    useEffect(() => {
        const center = venueInfo.venues.reduce(
            (accumulator, venue) => [
                accumulator[0] + venue.location[0],
                accumulator[1] + venue.location[1],
            ],
            [0, 0]
        );

        const midCoord = [
            center[0] / venueInfo.venues.length,
            center[1] / venueInfo.venues.length,
        ];

        setMidCoord(midCoord);
    }, []);

    const eventPointerInfo = (venueInfo: VenueInfo) => {
        const [x, y] = venueInfo.location;
        return (
            <Marker position={[x, y]}>
                <Popup>
                    <div className="venue-popup">
                        <Typography
                            variant="body1"
                            sx={{ marginBottom: "0.8em" }}
                        >
                            Venue for days:{" "}
                            {venueInfo.days.map((day, index) =>
                                index !== venueInfo.days.length - 1
                                    ? `${day}, `
                                    : day
                            )}
                        </Typography>
                        <a
                            href={`https://www.google.com/maps/dir/?api=1&destination=${x},${y}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Typography
                                variant="body1"
                                sx={{ marginBottom: "0.8em" }}
                            >
                                {venueInfo.name}
                            </Typography>
                        </a>
                    </div>
                </Popup>
            </Marker>
        );
    };

    return (
        <div className="venue-map-top-container">
            <Typography variant="h2TitleFont" sx={{ marginBottom: "0.8em" }}>
                Directions
            </Typography>

            {venueInfo.confirmed ? (
                <div className="venue-map-container">
                    <MapContainer
                        key={`${eventMidCoord[0]}_${eventMidCoord[1]}`}
                        center={[eventMidCoord[0], eventMidCoord[1]]}
                        zoom={17}
                        scrollWheelZoom={false}
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {venueInfo.venues.map((data) => {
                            return eventPointerInfo(data);
                        })}
                    </MapContainer>
                </div>
            ) : (
                <Typography variant="body1">
                    Coming soon! Stay tuned for more information.
                </Typography>
            )}
        </div>
    );
}
