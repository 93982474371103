import React, { CSSProperties, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { Snackbar, Box, Alert, IconButton, Link, InputAdornment, FormControl, Input } from "@mui/material";

type Validator = {
    validatorFunction: (text: string) => boolean;
    errorMessage: string
};

type Props = {
    name: string;
    text: string;
    onSave: (text: string) => Promise<void>;
    style?: CSSProperties;
    isEditable?: boolean;
    isLink?: boolean;
    validators?: Validator[];
}

const EditableText = ({ 
    name, 
    text, 
    onSave, 
    style, 
    isEditable = true, 
    isLink = false, 
    validators = [],
}: Props) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editorValue, setEditorValue] = useState<string>(text);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");

    const saveChanges = async () => {
        const value = editorValue.trim();
        for (const validator of validators) {
            const { validatorFunction, errorMessage } = validator;
            if (!validatorFunction(value)) {
                setSnackbarMessage(errorMessage);
                return;
            }
        }
        
        // don't close if there's some error editing on the server
        try {
            await onSave(value);
            setIsEditing(false);
        } catch (e) {
            console.log(e);
        }
    };

    const discardChanges = () => {
        setEditorValue(text);
        setIsEditing(false);
    };

    const endAdornment = (
        <InputAdornment position="end">
            <IconButton
                color="primary"
                onClick={saveChanges}
            >
                <SaveIcon />
            </IconButton>
            <IconButton
                color="error"
                onClick={discardChanges}
            >
                <CloseIcon />
            </IconButton>
        </InputAdornment>
    );

    const editingInput = (
        <FormControl>
            <Input
                id={name} 
                error={editorValue.trim().length === 0}
                value={editorValue}
                sx={style}
                endAdornment={endAdornment}
                placeholder={name}
                onChange={event => setEditorValue(event.target.value)}
            />
        </FormControl>
    );

    const normalText = (
        <Box sx={style}>
            {isLink
                ? <Link href={text}>{text}</Link>
                : text
            }
            {isEditable && (
                <IconButton
                    color="primary"
                    onClick={() => setIsEditing(true)}
                >
                    <EditIcon />
                </IconButton>
            )}
        </Box>
    );

    return (
        <Box>
            {isEditing ? editingInput : normalText}
            
            <Snackbar 
                open={snackbarMessage !== ""} 
                autoHideDuration={3000} 
                onClose={() => setSnackbarMessage("")} 
            >
                <Alert onClose={() => setSnackbarMessage("")} severity="error" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    )
};

export default React.memo(EditableText);
