import { Button, Box, Popover } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { SERVER_LINK } from "../../../apis/constant";

type Props = {
    setLogin: (accessToken: string, userEmail: string) => void;
    nav: (url: string) => void;
    requiresConfirmation?: boolean;
    style?: any;
};

const LogoutButton = ({
    setLogin,
    nav,
    style,
    requiresConfirmation = false,
}: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [anchorElement, setAnchorElement] = useState<HTMLButtonElement>();

    const logout = () => {
        // Resend verification link
        setIsLoading(true);
        setAnchorElement(undefined);

        axios
            .post(SERVER_LINK + "/logout", {}, { withCredentials: true })
            .then((response: any) => {
                if (response.status === 200) {
                    setLogin("", "");
                }
            })
            .finally(() => {
                nav("/");
            });
    };

    return (
        <>
            <Button
                variant="contained"
                color="error"
                sx={style}
                disabled={isLoading}
                onClick={(event) =>
                    requiresConfirmation
                        ? setAnchorElement(event.currentTarget)
                        : logout()
                }
            >
                {isLoading ? "Logging Out..." : "Logout"}
            </Button>

            <Popover
                open={!!anchorElement}
                anchorEl={anchorElement}
                onClose={() => setAnchorElement(undefined)}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
            >
                <Box sx={{ backgroundColor: "black", padding: 2 }}>
                    Are you sure you want to logout?
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={() => setAnchorElement(undefined)}>
                            Cancel
                        </Button>
                        <Button color="error" onClick={logout}>
                            Logout
                        </Button>
                    </Box>
                </Box>
            </Popover>
        </>
    );
};

export default React.memo(LogoutButton);
