import { IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { User } from '../../../../types/types';
import UserInfo from './components/UserInfo';
import { useLogin } from '../../../../hooks/LoginProvider';
import { updateUser } from '../../../../apis/user';
import "./styles.css";

type Props = {
    user: User;
    refreshPage: () => Promise<void>;
    openSnackbar: (severity: "error" | "success", message: string) => void;
    setIsLoading: (isLoading: boolean) => void;
};

const NAME_ERROR = "Name cannot be empty";
const TAGS_ERROR = "At least one tag must be chosen";

const UserInformation = ({ user, refreshPage, openSnackbar, setIsLoading }: Props) => {
    const { accessToken, setLogin } = useLogin();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editableUser, setEditableUser] = useState<User>(user);

    const saveChanges = async () => {
        if (editableUser.name.trim().length === 0) {
            openSnackbar("error", NAME_ERROR);
            return;
        }

        if (editableUser.tags.length === 0) {
            openSnackbar("error", TAGS_ERROR);
            return;
        }

        try {
            setIsLoading(true);
            await updateUser({
                name: editableUser.name.trim(),
                tags: editableUser.tags,
                profilePicture: editableUser.profilePicture,
            }, accessToken, setLogin);
            setIsEditing(false);
            await refreshPage();
        } catch (e) {
            openSnackbar("error", "Something went wrong, try again later");
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const discardChanges = () => {
        setEditableUser(user);
        setIsEditing(false);
    };

    const editButton = (
        <IconButton
            color="primary"
            onClick={() => setIsEditing(true)}
            sx={{ paddingLeft: 2 }}
        >
            <EditIcon />
        </IconButton>
    );

    const endEditButtons = (
        <>
            <IconButton
                color="primary"
                onClick={saveChanges}
                sx={{ paddingLeft: 2 }}
            >
                <SaveIcon />
            </IconButton>
            <IconButton
                color="error"
                onClick={discardChanges}
            >
                <CloseIcon />
            </IconButton>
        </>
    );

    return (
        <div style={{ border: "1px solid white", borderRadius: 10, padding: 16, marginBottom: 16 }}>
            <div style={{ display: "flex", justifyContent: "center", paddingBottom: 8 }}>
                <Typography variant="h3TitleFont">
                    <u className="header-profile">PROFILE</u>
                </Typography>
                {isEditing ? endEditButtons : editButton}
            </div>

            <UserInfo
                user={editableUser}
                onEdit={setEditableUser}
                isEditing={isEditing}
            />
        </div>
    );
};

export default React.memo(UserInformation);
