import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";

import { getUser } from "../../apis/user";
import schedule from "../../dataFiles/Schedule.json";
import { useEventInfo } from "../../hooks/EventInfoProvider";
import { LoginContext } from "../../hooks/LoginProvider";
import { EventDetails } from "../../types/types";
import { dateDiffInDays } from "../../utils/dateTimeHelpers";

import DaySelector from "../../components/DaySelector";
import EventScheduleDetail from "./components/EventScheduleDetail";

import "./EventSchedule.css";

enum pageStates {
    loading,
    error,
    loaded
}

export default function EventSchedule() {
    const eventInfo = useEventInfo();
    const currDay = dateDiffInDays(
        eventInfo?.eventStartDateTime || new Date(),
        new Date()
    );

    const { accessToken, setLogin } = useContext(LoginContext);

    const [currDaySelected, setCurrDaySelected] = useState(0);
    const [currDateSelected, setCurrDateSelected] = useState(new Date());
    const [eventList, setEventList] = useState([-1]);
    const [pageState, setPageState] = useState(pageStates.loading);

    const eventSchedule = useRef<Array<Array<EventDetails>> | undefined>(
        undefined
    );

    // Get event schedule and user's event list
    useEffect(() => {
        //sort event schedule and store
        eventSchedule.current = schedule.events
            .sort((event1, event2) => (event1.day < event2.day ? -1 : 1))
            .map((data) => data.events);

        // fetch user's completed events
        getUser(accessToken, setLogin).then(
            (user) => {
                setEventList(user.eventsParticipated);
                setPageState(pageStates.loaded);
            },
            (e) => {
                console.log(e);
                setPageState(pageStates.error);
            }
        );
    }, [accessToken, setLogin]);

    // Calculate curr selected date based on curr selected day
    useEffect(() => {
        const startDate = eventInfo?.eventStartDateTime || new Date();
        setCurrDateSelected(
            new Date(
                startDate.getFullYear(),
                startDate.getMonth(),
                startDate.getDate() + currDaySelected
            )
        );
    }, [currDaySelected]);

    const renderTimeline = (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                paddingBottom: "20px",
            }}
        >
            {[...Array(eventSchedule.current?.length)].map(
                (ignore: number, index: number) => {
                    return (
                        <DaySelector
                            key={index}
                            currentDay={index + 1}
                            isDayPassed={index < currDay}
                            isSelected={index === currDaySelected}
                            isCurrDay={index === currDay}
                            hasPrevDay={index !== 0}
                            handleSelectDay={() => setCurrDaySelected(index)}
                            daysLeftTillCurrDay={
                                currDay <= 0
                                    ? -currDay + index
                                    : index - currDay
                            }
                        />
                    );
                }
            )}
        </Box>
    );

    const renderEvents = () => {
        if (!eventSchedule.current) {
            return <></>;
        }

        if (pageState === pageStates.loading) {
            return <CircularProgress />;
        }

        if (pageState === pageStates.error) {
            return (
                <Typography variant="h3">
                    There was an error loading the schedule. Try reloading the page.
                </Typography>
            );
        }

        const todayEvents = eventSchedule.current[currDaySelected];

        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
                style={{ marginBottom: "100px" }}
            >
                {todayEvents.map((item) => (
                    <EventScheduleDetail
                        key={item.eventId}
                        eventDetail={item}
                        eventList={eventList}
                        currDaySelected={currDaySelected}
                    />
                ))}
            </Box>
        );
    };

    return (
        <Stack className="schedule-container" alignItems="center" spacing={2}>
            <Typography variant="h1">Schedule</Typography>

            {renderTimeline}
            {renderEvents()}
        </Stack>
    );
}
