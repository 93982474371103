import axios from "axios";
import { SERVER_LINK } from "./constant";

// sends a request, and refreshes the access token if it is not valid

const REFRESH_URL = SERVER_LINK + "/refresh"

const refresh = async (setLogin: (token: string, email: string) => void) => {
    const refreshRes = await axios.post(REFRESH_URL, undefined, { withCredentials: true });
    const { accessToken, email } = refreshRes.data.data;
    setLogin(accessToken, email);
    return accessToken;
}

export const authPost = async (
    url: string, 
    body: any, 
    accessToken: string,
    setLogin: (token: string, email: string) => void,
) => {
    // default access token from the default context is empty
    if (!accessToken) {
        accessToken = await refresh(setLogin);
    }
    const config = { headers: { authorization: `Bearer ${accessToken}` } };

    try {
        const res1 = await axios.post(url, body, config);
        return res1;
    } catch (e: any) {
        if (e.response.status === 498 && e.response.data.msg === "Invalid access token") {
            const accessToken = await refresh(setLogin);
            const config = { headers: { authorization: `Bearer ${accessToken}` } };

            const res2 = await axios.post(url, body, config);
            return res2;
        }

        throw e;
    }
}

export const authPut = async (
    url: string, 
    body: any, 
    accessToken: string,
    setLogin: (token: string, email: string) => void,
) => {
    // default access token from the default context is empty
    if (!accessToken) {
        accessToken = await refresh(setLogin);
    }
    const config = { headers: { authorization: `Bearer ${accessToken}` } };

    try {
        const res1 = await axios.put(url, body, config);
        return res1;
    } catch (e: any) {
        if (e.response.status === 498 && e.response.data.msg === "Invalid access token") {
            const accessToken = await refresh(setLogin);
            const config = { headers: { authorization: `Bearer ${accessToken}` } };

            const res2 = await axios.put(url, body, config);
            return res2;
        }

        throw e;
    }
}

export const authGet = async (
    url: string, 
    accessToken: string,
    setLogin: (token: string, email: string) => void,
) => {
    // default access token from the default context is empty
    if (!accessToken) {
        accessToken = await refresh(setLogin);
    }
    const config = { headers: { authorization: `Bearer ${accessToken}` } };

    try {
        const res1 = await axios.get(url, config);
        return res1;
    } catch (e: any) {
        if (e.response.status === 498 && e.response.data.msg === "Invalid access token") {
            const accessToken = await refresh(setLogin);
            const config = { headers: { authorization: `Bearer ${accessToken}` } };

            const res2 = await axios.get(url, config);
            return res2;
        }

        throw e;
    }
}

export const authDelete = async (
    url: string, 
    accessToken: string,
    setLogin: (token: string, email: string) => void,
) => {
    // default access token from the default context is empty
    if (!accessToken) {
        accessToken = await refresh(setLogin);
    }
    const config = { headers: { authorization: `Bearer ${accessToken}` } };

    try {
        const res1 = await axios.delete(url, config);
        return res1;
    } catch (e: any) {
        if (e.response.status === 498 && e.response.data.msg === "Invalid access token") {
            const accessToken = await refresh(setLogin);
            const config = { headers: { authorization: `Bearer ${accessToken}` } };

            const res2 = await axios.delete(url, config);
            return res2;
        }

        throw e;
    }
}
