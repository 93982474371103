import React from "react";
import { Link } from "react-scroll";

import { Box, Typography, Toolbar, AppBar } from "@mui/material";
import { ThemeContext } from "@mui/styled-engine";
import { animateScroll as scroll } from "react-scroll";
import { useNavigate, useLocation } from "react-router-dom";

interface Props {
    /**
     * Double array to store the navigation section links
     * [Section Name, Section id]
     */
    navItems: string[][];

    /**
     * Whether it's same page navigation or does clicking a link go to other pages
     */
    isSamePageNavigation: boolean;

    /**
     * Component to be placed at the front of the navItems in the navBar
     */
    navBarStartComponent?: React.ReactNode;

    /**
     * Component to be placed at the back of the navItems in the navBar
     */
    navBarBackComponent?: React.ReactNode;

    /**
     * Style of navbar, if not specified, use default settings
     */
    navBarStyle?: React.CSSProperties;

    /**
     * display setting of navBar, for responsive breakpoints.
     */
    navBarDisplay?: any;
}

function NavBar(props: Props) {
    const [activeSectionIndex, setActiveSectionIndex] = React.useState(0);
    const theme: any = React.useContext(ThemeContext);

    const location = useLocation();
    const nav = useNavigate();
    const navigate = (to: string) => {
        // navigate at the top of the page
        scroll.scrollToTop({ duration: 0 });
        nav(to);
    };

    const headerButtonStyles = {
        activeSection: {
            color: theme.palette.secondary.main,
            fontFamily: theme.typography.fontFamily,
            ...theme.typography.h5,
        },
        inactiveSection: {
            color: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamily,
            ...theme.typography.body1,
            "&:hover": {
                fontWeight: "600",
            },
        },
    };

    const renderBarTextButton = (
        index: number,
        sectionId: string,
        sectionName: string
    ) => {
        return (
            <Link
                to={sectionId}
                spy
                smooth
                offset={-50}
                duration={500}
                onSetActive={() => {
                    setActiveSectionIndex(index);
                }}
                onClick={() => {
                    if (!props.isSamePageNavigation) {
                        navigate(sectionId);
                    }
                }}
            >
                <Typography
                    variant="body1"
                    sx={
                        (!props.isSamePageNavigation &&
                            location.pathname === sectionId) ||
                        (props.isSamePageNavigation &&
                            activeSectionIndex === index)
                            ? headerButtonStyles.activeSection
                            : headerButtonStyles.inactiveSection
                    }
                >
                    {sectionName}
                </Typography>
            </Link>
        );
    };

    const renderBarContents = () => {
        return (
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                {props.navItems.map((item, index) => (
                    <Box
                        m={1}
                        sx={{
                            cursor: "pointer",
                            marginTop: "auto",
                            marginBottom: "auto",
                        }}
                        key={index}
                    >
                        {renderBarTextButton(index, item[1], item[0])}
                    </Box>
                ))}
            </Box>
        );
    };

    return (
        <AppBar
            component="nav"
            style={props.navBarStyle ?? styles.defaultNavBar}
            elevation={0}
            sx={{
                display: props.navBarDisplay,
            }}
        >
            <Toolbar
                sx={{
                    display: "flex",
                    flexDirecton: "row",
                    width: "100%",
                }}
            >
                {props.navBarStartComponent}
                {renderBarContents()}
                {props.navBarBackComponent}
            </Toolbar>
        </AppBar>
    );
}

const styles: { [key: string]: React.CSSProperties } = {
    defaultNavBar: {
        // Linear gradient for the background
        background:
            "linear-gradient(180deg, #002536fd 20%, #051a54d0 75%, #00000000 100%)",
        color: "#ffffff",
        // cursor: "url('" + IMAGES["ufo"] + "'), default",
    },
};

export default React.memo(NavBar);
