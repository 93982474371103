import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { SERVER_LINK } from "../apis/constant";

const REFRESH_URL = SERVER_LINK + "/refresh"

export interface LoginContextType {
    accessToken: string;
    setLogin: (token: string, email: string) => void;
    userEmail: string;
};

export const useLogin = () => {
    return useContext(LoginContext);
};

export const LoginContext =
    React.createContext<LoginContextType>({
        accessToken: "",
        setLogin: (token: string, email: string) => {},
        userEmail: "",
    });

const LoginProvider = ({ children }: { children: JSX.Element }) => {
    const [accessToken, setAccessToken] = useState<string>("");
    const [userEmail, setUserEmail] = useState<string>("");

    const setAccessTokenAndEmail = (token: string, email: string) => {
        setAccessToken(token);
        setUserEmail(email);
    };

    useEffect(() => {
        axios.post(REFRESH_URL, undefined, { withCredentials: true })
            .then(
                async (res) => {
                    const { accessToken, email } = res.data.data;
                    setAccessTokenAndEmail(accessToken, email);
                }
            ).catch(err => console.log(err));
    }, []);

    return (
        <LoginContext.Provider value={{
            accessToken: accessToken,
            setLogin: setAccessTokenAndEmail,
            userEmail: userEmail,
        }}>
            {children}
        </LoginContext.Provider>
    );
}

export default LoginProvider;