import React from "react";
import PrizeItem from "./PrizeItem";

interface Props {
    prizeList: { prize: string[]; title: string; imgPath: string }[];
}

function PrizePyramid(props: Props) {
    return (
        <div>
            <PrizeItem
                prize={props.prizeList[0].prize}
                title={props.prizeList[0].title}
                imgPath={props.prizeList[0].imgPath}
                description=""
            />
            <div style={styles.bottomDiv}>
                <PrizeItem
                    prize={props.prizeList[1].prize}
                    title={props.prizeList[1].title}
                    imgPath={props.prizeList[1].imgPath}
                    description=""
                />
                <PrizeItem
                    prize={props.prizeList[2].prize}
                    title={props.prizeList[2].title}
                    imgPath={props.prizeList[2].imgPath}
                    description=""
                />
            </div>
        </div>
    );
}

const styles: { [key: string]: React.CSSProperties } = {
    bottomDiv: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
};

export default React.memo(PrizePyramid);
