import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import IMAGES from "../../../../../assets/images";
import { JudgeDetails } from "../../../../../constants/judgeInfo";
import TalkInfoPopup from "./TalkInfoPopup";

interface Props {
    speakerDetails: JudgeDetails;
}

function SpeakerInfo({ speakerDetails }: Props) {
    const [isOpenDetailedPopup, setIsOpenDetailedPopup] = React.useState(false);

    const renderSpeakerInfo = () => {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "270px",
                    minHeight: "230px",
                    padding: "20px",
                }}
            >
                <img
                    src={IMAGES[speakerDetails.imgPath]}
                    alt={speakerDetails.name}
                    style={styles.speakerImg}
                />
                <Typography variant="h5">{speakerDetails.name}</Typography>
                <Typography variant="subtitle1" style={styles.textDescription}>
                    {speakerDetails.jobTitle}
                </Typography>
                <Typography
                    variant="subtitle1"
                    sx={[styles.textDescription, { marginTop: "0.3rem" }]}
                >
                    {speakerDetails.company}
                </Typography>
            </Box>
        );
    };

    const renderTalkDescription = () => {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "300px",
                    marginRight: "1rem",
                    marginLeft: "1rem",
                }}
            >
                <Typography color="secondary" variant="h5TitleFont">
                    {speakerDetails.talk?.title}
                </Typography>
                <Typography
                    variant="subtitle1"
                    sx={[styles.textDescription, { marginTop: "0.2rem" }]}
                >
                    {speakerDetails.talk?.date} |{" "}
                    {speakerDetails.talk?.location}
                </Typography>
                <Typography
                    variant="caption"
                    sx={[
                        styles.talkDescription,
                        { marginTop: "1rem", lineHeight: 1.2 },
                    ]}
                >
                    {speakerDetails.talk?.description}
                </Typography>
            </Box>
        );
    };

    const renderInfoButton = (display?: any) => {
        return (
            <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                sx={{
                    display: display,
                    marginTop: 0,
                    marginBottom: "auto",
                    padding: 0,
                    marginRight: 0,
                    marginLeft: "auto",
                }}
                onClick={() => setIsOpenDetailedPopup(true)}
            >
                <InfoOutlinedIcon />
            </IconButton>
        );
    };

    return (
        <div>
            <Box sx={styles.speakerItem}>
                {/* Placed here for the mobile version to show, could not use position absolute as for some reason it does not show up on iphone */}
                {renderInfoButton({ xs: "flex", sm: "none" })}
                {renderTalkDescription()}
                <Box sx={styles.divider}>|</Box>
                {renderSpeakerInfo()}
                {renderInfoButton({ xs: "none", sm: "flex" })}
            </Box>
            <TalkInfoPopup
                isOpen={isOpenDetailedPopup}
                onClose={() => setIsOpenDetailedPopup(false)}
                judgeDetails={speakerDetails}
            />
        </div>
    );
}

export default React.memo(SpeakerInfo);

const styles: { [key: string]: any } = {
    speakerImg: {
        width: 120,
        height: 120,
        borderRadius: "50%",
        objectFit: "cover",
        maxWidth: 120,
    },
    speakerItem: {
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(2px)",
        background: "#08376F64",
        marginBottom: "20px",
        marginLeft: { xs: "0", sm: "20px" },
        borderRadius: "10px",
        padding: { xs: "20px", sm: "10px" },
        minHeight: "230px",
    },
    textDescription: {
        textAlign: "center",
        width: "100%",
    },

    talkDescription: {
        textAlign: { xs: "center", sm: "left" },
        width: "100%",
        textOverflow: "ellipsis",
        overflow: "hidden",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 4,
    },
    divider: {
        display: { xs: "none", sm: "block" },
        color: "#0d6a91C4",
        fontSize: "5rem",
        marginLeft: "1rem",
        marginRight: "1rem",
    },
};
