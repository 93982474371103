import React from "react";
import { Box, Typography } from "@mui/material";
import IMAGES from "../../../../assets/images";
import speakersList from "../../../../dataFiles/Speakers.json";
import GUEST_DETAILS from "../../../../dataFiles/GuestDetails.json";

import Carousel from "react-material-ui-carousel";
import sponsorsInfo from "../../../../dataFiles/Sponsors.json";
import { ThemeContext } from "@mui/styled-engine";

import CircleIcon from "@mui/icons-material/Circle";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import SpeakerInfo from "./components/SpeakerInfo";

const CAROUSEL_ARROW_SIZE = {
    xs: "1.3rem",
    sm: "2rem",
    md: "2rem",
    lg: "2.2rem",
};

interface Props {
    /** For styling subsections, such as margin top spacing etc */
    subSectionStyle?: any;
}

export default function Guests(props: Props) {
    const theme: any = React.useContext(ThemeContext);

    const carouselStyles: any = {
        indicator: {
            cursor: "pointer",
            transition: "200ms",
            padding: 0,
            ":hover": {
                color: "#fcc56d",
            },
            ":active": {
                color: "#fcc56d",
            },
        },
        activeIndicator: {
            color: theme.palette.secondary.main,
        },
    };

    const sponsorsCarousel = () => {
        return (
            <Carousel
                navButtonsAlwaysVisible
                NextIcon={
                    <NavigateNextIcon sx={{ fontSize: CAROUSEL_ARROW_SIZE }} />
                }
                PrevIcon={
                    <NavigateBeforeIcon
                        sx={{ fontSize: CAROUSEL_ARROW_SIZE }}
                    />
                }
                navButtonsProps={{
                    style: {
                        backgroundColor: "#ffad00AA",
                        color: "#002536fd",
                        padding: 0,
                        marginLeft: 0,
                        marginRight: 0,
                    },
                }}
                IndicatorIcon={
                    <CircleIcon
                        sx={[
                            {
                                fontSize: {
                                    xs: "0.8rem",
                                    sm: "1rem",
                                    md: "1rem",
                                    lg: "1rem",
                                },
                            },
                            carouselStyles.indicator,
                        ]}
                    />
                }
                indicatorIconButtonProps={{
                    style: { color: "#cf4b17" },
                }}
                activeIndicatorIconButtonProps={{
                    style: carouselStyles.activeIndicator,
                }}
                sx={{ maxWidth: "1000px", maxHeight: "450px", margin: "auto" }}
                interval={6000}
            >
                {sponsorsInfo.premium.map((sponsor) => {
                    return (
                        <Box
                            key={sponsor.name}
                            sx={{
                                textAlign: "center",
                            }}
                        >
                            <a
                                href={sponsor.link}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={IMAGES[sponsor.bannerPhotoName]}
                                    style={{
                                        width: "1000px",
                                        height: "281px",
                                        objectFit: "cover",
                                    }}
                                    alt={sponsor.name}
                                />
                            </a>
                        </Box>
                    );
                })}
            </Carousel>
        );
    };

    const renderSpeakersSection = (containerStyle?: any) => {
        return (
            <Box sx={containerStyle}>
                <Typography variant="h2TitleFont">Talks</Typography>
                {speakersList.confirmed ? (
                    <div style={styles.speakersContainer}>
                        {speakersList.speakers.map((speakerName, index) => {
                            return (
                                <SpeakerInfo
                                    key={index}
                                    speakerDetails={{
                                        name: speakerName,
                                        ...GUEST_DETAILS[
                                            speakerName as keyof typeof GUEST_DETAILS
                                        ],
                                    }}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <Typography variant="body1">
                        Coming soon! Stay tuned for more information.
                    </Typography>
                )}
            </Box>
        );
    };

    const sponsorTier = (sponsors: any) => {
        return (
            <Box style={styles.sponsorTier}>
                {sponsors.map(
                    (
                        sponsor: {
                            logoPhotoName: string | number;
                            name: string | undefined;
                            width: string | undefined;
                        },
                        index: React.Key | null | undefined
                    ) => {
                        return (
                            <img
                                key={index}
                                src={IMAGES[sponsor.logoPhotoName]}
                                style={{
                                    width: sponsor.width || "25vh",
                                    margin: 20,
                                }}
                                alt={sponsor.name}
                            />
                        );
                    }
                )}
            </Box>
        );
    };

    return (
        <>
            {renderSpeakersSection()}

            <Box sx={props.subSectionStyle}>
                <Typography variant="h2TitleFont">Sponsors</Typography>
                {sponsorsInfo.confirmed ? (
                    <>
                        <Box sx={{ marginTop: "2rem" }}>{sponsorsCarousel()}</Box>
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: "4rem",
                            }}
                        >
                            <div style={styles.sponsorsContainer}>
                                {sponsorsInfo.PremiumPlus.length > 0 && (
                                    <div>
                                        <Typography variant="h2">Premium+</Typography>
                                        {sponsorTier(sponsorsInfo.PremiumPlus)}
                                    </div>
                                )}
                                {sponsorsInfo.PremiumRegular.length > 0 && (
                                    <div style={styles.sponsorTierContainer}>
                                        <Typography variant="h2">Premium</Typography>
                                        {sponsorTier(sponsorsInfo.PremiumRegular)}
                                    </div>
                                )}
                                {sponsorsInfo.Basic.length > 0 && (
                                    <div style={styles.sponsorTierContainer}>
                                        <Typography variant="h2">Basic</Typography>
                                        {sponsorTier(sponsorsInfo.Basic)}
                                    </div>
                                )}
                            </div>
                        </Box>
                    </>
                ) : (
                    <Typography variant="body1">
                        Coming soon! Stay tuned for more information.
                    </Typography>
                )}
            </Box>
        </>
    );
}

const styles: { [key: string]: React.CSSProperties } = {
    speakersContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        margin: 10,
    },

    //sponsor related
    sponsorsContainer: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        margin: 10,
        padding: "20px",
        backdropFilter: "blur(2px)",
        background: "#052142a0",
        borderRadius: "10px",
        maxWidth: "1200px",
        width: "100%",
    },
    sponsorTierContainer: {
        marginTop: "2rem",
    },
    sponsorTier: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        margin: 10,
    },
    sponsorTierTitle: {
        textAlign: "center",
        width: "100%",
        margin: 10,
    },
};
