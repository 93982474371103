import React from "react";
import { Typography } from "@mui/material";
import CreateTeam from "./CreateTeam";

type Props = {
    onCreate: () => void;
    openSnackbar: (severity: "error" | "success", message: string) => void;
    setIsLoading: (isLoading: boolean) => void;
}

const NoTeam = ({ onCreate, openSnackbar, setIsLoading }: Props) => {
    return (
        <div>
            <Typography variant="h2">Looks like you're not in a team yet!</Typography>
            <Typography>You can look for a team on discord, or create a team using the button below!</Typography>
            <CreateTeam onSuccess={onCreate} setIsLoading={setIsLoading} openSnackbar={openSnackbar} />
        </div> 
    );
};

export default React.memo(NoTeam);
