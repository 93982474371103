import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import criteriaList from "../../../../dataFiles/JudgingCriteria.json";
import judgesList from "../../../../dataFiles/Judges.json";
import GUEST_DETAILS from "../../../../dataFiles/GuestDetails.json";

import { Typography, Box } from "@mui/material";
import { ThemeContext } from "@mui/styled-engine";

import "./Judging.css";
import JudgeInfo from "./components/JudgeInfo";

interface Props {
    /** For styling subsections, such as margin top spacing etc */
    subSectionStyle?: any;
}

export default function Judging(props: Props) {
    const theme: any = React.useContext(ThemeContext);

    const stylings = {
        cellTitle: {
            color: theme.palette.primary.main,
            ...theme.typography.h5,
            fontFamily: theme.typography.fontFamily,
        },
        cellContent: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.primary.main,
            ...theme.typography.body1,
        },
    };

    const renderJudgingCriteriaSection = (containerStyle?: any) => {
        return (
            <Box sx={containerStyle}>
                <Typography variant="h2TitleFont">Judging Criteria</Typography>
                {criteriaList.confirmed ? (
                    <TableContainer
                        sx={{ marginTop: 1 }}
                        className="judging-criteria-section"
                    >
                        <Table
                            sx={{
                                // backgroundColor: "#08376f64",
                                backdropFilter: "blur(2px)",
                                background: "#08376F64",
                                color: theme.palette.primary.main,
                                borderRadius: "10px",
                            }}
                            aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={stylings.cellTitle}>
                                        Criteria
                                    </TableCell>
                                    <TableCell sx={stylings.cellTitle}>
                                        Description
                                    </TableCell>
                                    <TableCell
                                        sx={stylings.cellTitle}
                                        align="right"
                                    >
                                        Weightage
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {criteriaList.criteria.map((row) => (
                                    <TableRow
                                        key={row.criteria}
                                        sx={{
                                            "&:last-child td, &:last-child th": {
                                                border: 0,
                                            },
                                        }}
                                    >
                                        <TableCell
                                            sx={stylings.cellContent}
                                            component="th"
                                            scope="row"
                                        >
                                            {row.criteria}
                                        </TableCell>
                                        <TableCell sx={stylings.cellContent}>
                                            {row.description}
                                        </TableCell>
                                        <TableCell
                                            sx={stylings.cellContent}
                                            align="right"
                                        >
                                            {row.percentage}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography variant="body1">
                        Coming soon! Stay tuned for more information.
                    </Typography>
                )}
            </Box>
        );
    };

    const renderJudgesSection = (containerStyle?: any) => {
        return (
            <Box sx={containerStyle}>
                <Typography variant="h2TitleFont">Judges</Typography>
                {judgesList.confirmed ? (
                    <div style={styles.judgesContainer}>
                        {judgesList.judges.map((name, index) => {
                            return (
                                <JudgeInfo
                                    key={index}
                                    judgeDetails={{
                                        name,
                                        ...GUEST_DETAILS[
                                            name as keyof typeof GUEST_DETAILS
                                        ],
                                    }}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <Typography variant="body1">
                        Coming soon! Stay tuned for more information.
                    </Typography>
                )}
            </Box>
        );
    };

    return (
        <div style={styles.mainContainer}>
            {renderJudgingCriteriaSection()}
            {renderJudgesSection(props.subSectionStyle)}
        </div>
    );
}

const styles: { [key: string]: React.CSSProperties } = {
    mainContainer: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
    },

    judgesContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "start",
        margin: 10,
    },
};
