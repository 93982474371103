import IMAGES from "../../../assets/images";

export default function BottomImage() {
    return (
        <div style={{ height: "400px", overflow: "hidden" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <img className="alien" src={IMAGES["alien3"]} alt="alien 3" />
                <img className="alien" src={IMAGES["alien1"]} alt="alien 1" />
                <img className="alien" src={IMAGES["alien2"]} alt="alien 2" />
            </div>

            <img style={{ marginLeft: "auto", marginRight: "auto" }} src={IMAGES["moon2"]} alt="moon" />
        </div>
    );
}