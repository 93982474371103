import { ProfilePicture, Tag, User } from "../../types/types"
import { BackendUser } from "./types";

const tagList = Object.values(Tag);
const pictureList = Object.values(ProfilePicture);

const mapTagsToNumbers = (tags: Tag[]): number[] => {
    return tags.map(tag => tagList.indexOf(tag));
};

const mapNumbersToTags = (numbers: number[]): Tag[] => {
    return numbers.map(number => tagList[number]);
};

const mapPictureToNumber = (picture: ProfilePicture): number => {
    return pictureList.indexOf(picture);
};

const mapNumberToPicture = (number: number): ProfilePicture => {
    return pictureList[number];
};

// forgive the any
export const mapUserDataToUser = (user: BackendUser): User => {
    return {
        ...user,
        tags: mapNumbersToTags(user.tags),
        profilePicture: mapNumberToPicture(user.profilePicture),
    };
};

export const mapUserToUserData = (
    user: Partial<User> & {
        tags: Tag[],
        profilePicture: ProfilePicture,
    }
): Partial<BackendUser> & {
    tags: number[],
    profilePicture: number,
} => {
    return {
        ...user,
        tags: mapTagsToNumbers(user.tags),
        profilePicture: mapPictureToNumber(user.profilePicture),
    };
};
