import { authGet, authPost, authPut } from "../authFetch";
import { mapUserDataToUser } from "../user/utils";
import { 
    AddMemberParams, 
    CreateTeamParams, 
    CreateTeamResponse, 
    EditTeamNameParams, 
    GetTeamParams, 
    GetTeamResponse, 
    JoinTeamParams, 
    RemoveMemberParams, 
    SubmitGameParams, 
    SubmitGameResponse
} from "./types";
import { SERVER_LINK } from "../constant";

const CREATE_TEAM_URL = SERVER_LINK + "/team";
const GET_TEAM_URL = SERVER_LINK + "/team";
const EDIT_TEAMNAME_URL = SERVER_LINK + "/team/name";
const ADD_MEMBER_URL = SERVER_LINK + "/team/invite";
const JOIN_TEAM_URL = SERVER_LINK + "/team/join";
const LEAVE_TEAM_URL = SERVER_LINK + "/team/leave";
const SUBMIT_GAME_URL = SERVER_LINK + "/team/submit";

export const createTeam = async (
    params: CreateTeamParams,
    accessToken: string, 
    setLogin: (accessToken: string, email: string) => void,
): Promise<CreateTeamResponse> => {
    try {
        const res = await authPost(CREATE_TEAM_URL, params, accessToken, setLogin);
        return res.data;
    } catch (err: any) {
        return Promise.reject(err.response.data.msg);
    }
};

export const getTeam = async (
    params: GetTeamParams,
    accessToken: string, 
    setLogin: (accessToken: string, email: string) => void,
): Promise<GetTeamResponse> => {
    const url = GET_TEAM_URL + "?" + new URLSearchParams({ teamId: params.teamId });

    try {
        const res = await authGet(url, accessToken, setLogin);
        const data = res.data.data;
        data.members = data.members.map((member: any) => mapUserDataToUser(member));
        return data;
    } catch (err: any) {
        return Promise.reject(err.response.data.msg);
    }
};

export const editTeamName = async (
    params: EditTeamNameParams,
    accessToken: string, 
    setLogin: (accessToken: string, email: string) => void,
): Promise<void> => {
    try {
        await authPut(EDIT_TEAMNAME_URL, params, accessToken, setLogin);
    } catch (err: any) {
        return Promise.reject(err.response.data.msg);
    }
};

// sends invite if the user can be added
export const addMember = async (
    params: AddMemberParams,
    accessToken: string, 
    setLogin: (accessToken: string, email: string) => void,
): Promise<void> => {
    try {
        await authPost(ADD_MEMBER_URL, params, accessToken, setLogin);
    } catch (err: any) {
        return Promise.reject(err.response.data.msg);
    }
};

// joins team that has invited you
export const joinTeam = async (
    params: JoinTeamParams,
    accessToken: string, 
    setLogin: (accessToken: string, email: string) => void,
): Promise<void> => {
    try {
        await authPost(JOIN_TEAM_URL, params, accessToken, setLogin);
    } catch (err: any) {
        return Promise.reject(err.response.data.msg);
    }
}

export const removeMember = async (
    params: RemoveMemberParams,
    accessToken: string, 
    setLogin: (accessToken: string, email: string) => void,
): Promise<void> => {
    try {
        await authPost(LEAVE_TEAM_URL, params, accessToken, setLogin);
    } catch (err: any) {
        return Promise.reject(err.response.data.msg);
    }
};

export const submitGame = async (
    params: SubmitGameParams,
    accessToken: string, 
    setLogin: (accessToken: string, email: string) => void,
): Promise<SubmitGameResponse> => {
    try {
        await authPost(SUBMIT_GAME_URL, params, accessToken, setLogin);
        return Promise.resolve({ submissionExists: true });
    } catch (err: any) {
        return Promise.reject(err.response.data);
    }
};
