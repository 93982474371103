import React, { useContext, useEffect, useState } from "react";

import eventInfoFile from "../dataFiles/EventInfo.json";

export interface EventInfoContextType {
    eventStartDateTime: Date;
    eventEndDateTime: Date;
    regStartDateTime: Date;
    regEndDateTime: Date;
    allowLogin: boolean,
    email: string;
    itchLink: string;
    discordLink: string;
    maxTeamSize: number;
}

export function useEventInfo() {
    return useContext(EventInfoContext);
}

export const EventInfoContext =
    React.createContext<EventInfoContextType | null>({
        eventStartDateTime: new Date(),
        eventEndDateTime: new Date(),
        regStartDateTime: new Date(),
        regEndDateTime: new Date(),
        allowLogin: false,
        email: "",
        itchLink: "",
        discordLink: "",
        maxTeamSize: 1,
    });

interface Props {
    children: JSX.Element;
}

export const EventInfoProvider = ({ children }: Props) => {
    const [eventInfo, setEventInfo] = useState<EventInfoContextType | null>(
        null
    );

    useEffect(() => {
        //date is in yyyy-mm-dd format
        //time is in hh:mm:ss format
        const eventStartDateTime = new Date(
            eventInfoFile.eventStartDate + " " + eventInfoFile.eventStartTime
        );
        const eventEndDateTime = new Date(
            eventInfoFile.eventEndDate + " " + eventInfoFile.eventEndTime
        );

        const regStartDateTime = new Date(
            eventInfoFile.regStartDate + " " + eventInfoFile.regStartTime
        )

        const regEndDateTime = new Date(
            eventInfoFile.regEndDate + " " + eventInfoFile.regEndTime
        )

        const email = eventInfoFile.contactEmail;
        const itchLink = eventInfoFile.itchLink;
        const discordLink = eventInfoFile.discordLink;

        const maxTeamSize = eventInfoFile.maxTeamSize;

        const currentDateTime = new Date();
        const allowLogin = currentDateTime >= regStartDateTime && currentDateTime <= regEndDateTime;

        setEventInfo({
            eventStartDateTime,
            eventEndDateTime,
            regStartDateTime,
            regEndDateTime,
            allowLogin,
            email,
            itchLink,
            discordLink,
            maxTeamSize,
        });
    }, []);

    return (
        <EventInfoContext.Provider value={eventInfo}>
            {children}
        </EventInfoContext.Provider>
    );
};
