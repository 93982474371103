import { Box, Typography } from "@mui/material";
import prizesList from "../../../../dataFiles/Prizes.json";
import PrizeItem from "./components/PrizeItem";
import PrizePyramid from "./components/PrizePyramid";
import IMAGES from "../../../../assets/images";
// import css
import "./prizes.css";

export default function Prizes() {
    return (
        <>
            <div className="prizes-section-container">
                <div style={styles.decoImgsContainer}>
                    <Box
                        component="img"
                        src={IMAGES["ufo"]}
                        alt={"prize-ufo"}
                        style={styles.prizeUfoImg}
                    />
                </div>

                <div className="">
                    <Box
                        component="img"
                        className="planet-img"
                        id="planet-img1"
                        src={IMAGES["planet1"]}
                    />

                    <Box
                        component="img"
                        className="planet-img"
                        id="planet-img2"
                        src={IMAGES["planet2"]}
                    />
                </div>

                {/* ufo img */}
                <Box
                    component="img"
                    src={IMAGES["ufo"]}
                    alt={"prize-ufo"}
                    style={styles.prizeUfoImg}
                />

                <br />
                <br />
                <br />

                <Box style={styles.prizesTitle}>
                    <Typography variant="h2TitleFont">Prizes</Typography>
                </Box>

                {prizesList.confirmed ? (
                    <>
                        <div style={styles.topPrizes}>
                            <PrizePyramid prizeList={prizesList.OverallTop3} />
                        </div>
                        <br />
                        <div style={styles.bonusCategories}>
                            {prizesList.BonusCategories.map((prize, index) => (
                                <PrizeItem
                                    key={index}
                                    imgPath=""
                                    title={prize.title}
                                    prize={prize.prize}
                                    description={prize.description}
                                />
                            ))}
                        </div>
                    </>
                ) : (
                    <Typography variant="body1" sx={{ height: 300 }}>
                        Coming soon! Stay tuned for more information.
                    </Typography>
                )}
            </div>
        </>
    );
}

const styles: { [key: string]: React.CSSProperties } = {
    topPrizes: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        justifyContent: "space-around",
    },
    bonusCategories: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        flexWrap: "wrap",
        marginTop: "3rem",
    },

    prizesTitle: {
        textAlign: "center",
        zIndex: 3,
    },

    decoImgsContainer: {
        position: "relative",
        height: 0,
        width: 0,
        zIndex: 1,
    },

    prizeUfoImg: {
        width: 200,
        height: 200,
        transform: "rotate(-45deg)",

        // center
        position: "relative",
        left: "calc(50% - 100px)",
        top: "125px",
        zIndex: 1,
    },
};
