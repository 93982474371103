import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Box,
    Slide,
    Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { JudgeDetails } from "../../../../../constants/judgeInfo";

import IMAGES from "../../../../../assets/images";

import CloseIcon from "@mui/icons-material/Close";

interface Props {
    isOpen: boolean;
    onClose: () => void;

    judgeDetails: JudgeDetails;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function JudgeDetailsPopup(props: Props) {
    return (
        <Dialog
            open={props.isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={props.onClose}
            aria-describedby="info-on-judges"
            sx={styles.background}
            PaperProps={{ style: styles.popupContainer }}
        >
            <DialogTitle sx={{ marginBottom: { xs: "1rem", sm: "0" } }}>
                <IconButton
                    aria-label="close"
                    onClick={props.onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: "white",
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={styles.contentContainer}>
                <img
                    src={IMAGES[props.judgeDetails.imgPath]}
                    alt={props.judgeDetails.name}
                    style={styles.img}
                />
                <Box sx={[{ marginLeft: "2rem" }, styles.textContainer]}>
                    <Typography color="secondary" variant="h4TitleFont">
                        {props.judgeDetails.name}
                    </Typography>
                    <Typography variant="subtitle1" sx={styles.textJobSubtitle}>
                        {props.judgeDetails.jobTitle}
                        <br />
                        {props.judgeDetails.company}
                    </Typography>
                    <Typography variant="body1" sx={{ marginTop: "2.4rem" }}>
                        {props.judgeDetails.description}
                    </Typography>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default React.memo(JudgeDetailsPopup);

const styles: { [key: string]: any } = {
    background: {
        backdropFilter: "blur(2px)",
        background: "#08376F64",
    },
    popupContainer: {
        backdropFilter: "blur(3px)",
        background: "#0c499164",
        maxWidth: "900px",
        width: "100%",
    },

    contentContainer: {
        display: "flex",
        flexDirection: "row",

        flexWrap: { xs: "wrap", sm: "nowrap" },
        justifyContent: { xs: "center", sm: "start" },
        alignItems: { xs: "center", sm: "start" },
    },
    textContainer: {
        display: "flex",
        flexDirection: "column",

        marginLeft: { xs: "0", sm: "2rem" },
        justifyContent: { xs: "center", sm: "start" },
        alignItems: { xs: "center", sm: "start" },
    },
    textJobSubtitle: {
        display: "flex",
        textAlign: { xs: "center", sm: "left" },
    },

    img: {
        width: 200,
        height: "auto",
        objectFit: "cover",
    },
};
