import { Box, TextField, Typography, Tooltip } from "@mui/material";
import React from "react";
import EditableTags from "./EditableTags";
import UserAvatar from "./UserAvatar";
import UserDiscord from "./UserDiscord";
import InfoIcon from "@mui/icons-material/Info";
import { User, ProfilePicture, Tag } from "../../../../../types/types";
import TagChip from "../../../../../components/TagChip";

type Props = {
    user: User;
    onEdit: (user: User) => void;
    isEditing: boolean;
};

const ContactInfo = ({ user, onEdit, isEditing }: Props) => {
    const {
        name,
        tags,
        profilePicture,
        discordName,
        email,
        eventsParticipated,
    } = user;
    const points = eventsParticipated.length;

    const setName = (name: string) => onEdit({ ...user, name: name });
    const setTags = (tags: Tag[]) => onEdit({ ...user, tags });
    const setProfilePicture = (profilePicture: ProfilePicture) =>
        onEdit({ ...user, profilePicture });

    const isValidName = name.trim().length > 0;

    const editableName = isEditing ? (
        <TextField
            error={!isValidName}
            variant="outlined"
            label="Name"
            value={name}
            onChange={(event) => setName(event.target.value)}
            sx={{
                "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                        borderColor: "white",
                        borderRadius: "10px",
                    },
                },
                marginTop: "15px",
                marginBottom: "20px",
            }}
        />
    ) : (
        <Typography variant="h2">{name}</Typography>
    );

    const tagDisplay = (
        <Box sx={{ padding: 3 }}>
            <Typography sx={{ paddingBottom: 1 }} variant="h4">
                Tags:{" "}
            </Typography>
            {isEditing ? (
                <EditableTags tags={tags} setTags={setTags} />
            ) : (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                    }}
                >
                    {tags.map((tag) => (
                        <div
                            style={{ paddingRight: 5, paddingBottom: 5 }}
                            key={email + tag}
                        >
                            <TagChip tag={tag} />
                        </div>
                    ))}
                </div>
            )}
        </Box>
    );

    const pointsDisplay = (
        <Box sx={{ padding: 3 }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="h4">Points: </Typography>
                <Tooltip
                    enterTouchDelay={0}
                    title="You can earn points by inputting the correct password for events in the Event Password panel below. The more points you have, the better your chances of winning the raffle."
                >
                    <InfoIcon />
                </Tooltip>
            </div>
            <Typography variant="body1">{points}</Typography>
        </Box>
    );

    return (
        <div style={{ paddingTop: 16 }}>
            <UserAvatar
                avatar={profilePicture}
                setPicture={setProfilePicture}
                isEditing={isEditing}
            />
            {editableName}
            {!isEditing && <Typography variant="subtitle1">{email}</Typography>}
            {tagDisplay}
            {!isEditing && <UserDiscord discordName={discordName} />}
            {!isEditing && pointsDisplay}
        </div>
    );
};

export default React.memo(ContactInfo);
