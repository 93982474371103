import { Stack, Typography } from "@mui/material";
import { memo } from "react";
import SanitizeHtml from "../../../components/SanitizeHtml";
import { EventDetails } from "../../../types/types";
import { getEventPasswordDeadline } from "../../../utils/dateTimeHelpers";

interface Props {
    eventDetail?: EventDetails;
    eventList: Array<number>;
    currDaySelected: number;
}

const COMPLETED_COLOR = "#AAFFAA";
const ENDED_COLOR = "#FFAAAA";

// TODO (for future):
// Find a better way to assign a unique ID to events. Currently, events have to be
// manually assigned unique IDs in the JSON file. One idea is to store the day and
// the end time together in the same string.

function EventScheduleDetail({
    eventDetail,
    eventList,
    currDaySelected,
}: Props) {
    const eventName = eventDetail?.eventName || "";
    const eventId =
        eventDetail?.eventId === undefined ? -1 : eventDetail?.eventId;
    const eventEndTime = eventDetail?.endTime || "";
    const eventPasswordHash = eventDetail?.passwordHash || "";

    const currDate = new Date();
    const eventPasswordDeadline = eventDetail ? getEventPasswordDeadline(currDaySelected, eventDetail) : new Date();

    const eventStatusText = () => {
        if (eventPasswordHash === "") {
            return;
        }

        if (eventList.includes(eventId)) {
            return (
                <Typography style={{ color: COMPLETED_COLOR }}>
                    You've successfully earned points for this event!
                </Typography>
            );
        }

        if (currDate > eventPasswordDeadline) {
            return (
                <Typography style={{ color: ENDED_COLOR }}>
                    The deadline to receive points for this event has passed.
                </Typography>
            );
        }

        return (
            <Typography>
                Submit the code for this event in the Dashboard page to earn points!
            </Typography>
        );
    };

    return (
        <Stack alignItems="flex-start" spacing={1} className="event-container">
            <Typography
                align="left"
                variant="h3TitleFont"
                sx={{
                    typography: {
                        xs: "h5TitleFont",
                        sm: "h4TitleFont",
                        md: "h3TitleFont",
                        lg: "h3TitleFont",
                    },
                }}
            >
                <SanitizeHtml dirtyHtml={eventName} />
            </Typography>

            <Typography
                align="left"
                style={{ wordBreak: "break-word", marginBottom: "15px" }}
            >
                Time: {eventDetail?.startTime}{" "}
                {eventEndTime === "" ? "onwards" : "- " + eventEndTime}
            </Typography>

            <Typography align="left" style={{ wordBreak: "break-word" }}>
                {eventDetail?.eventDesc || ""}
            </Typography>

            <div style={{ marginTop: "20px" }}>{eventStatusText()}</div>
        </Stack>
    );
}

export default memo(EventScheduleDetail);
