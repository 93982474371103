import React from 'react';
import { Chip } from '@mui/material';
import { Tag } from '../../types/types';
import mainTheme from '../../constants/theme';

type Props = {
    tag: Tag;
    selected?: boolean;
    onClick?: () => void;
    height?: number;
    font?: string;
};

const COLOR_MAP: { 
    [tag: string]: "primary" | "secondary" | "success" | "info" | "warning"; 
} = {
    [Tag.PROGRAMMER]: "success",
    [Tag.ARTIST]: "primary",
    [Tag.DESIGNER]: "secondary",
    [Tag.WRITER]: "info",
    [Tag.MUSICIAN]: "warning",
};

const TagChip = ({ tag, selected, onClick, height = 32, font }: Props) => {
    const variant = selected === undefined || selected ? "filled" : "outlined";

    return (
        <Chip
            label={tag}
            color={COLOR_MAP[tag]}
            onClick={onClick}
            sx={{
                height: height,
                fontSize: `calc(${height / 32} * ${mainTheme.typography.body1.fontSize})`,
                fontFamily: font,
            }}
            variant={variant}
        />
    );
};

export default React.memo(TagChip);
