import React from "react";
import { Box, Typography } from "@mui/material";

import faqList from "../../../../dataFiles/FAQ.json";
import FaqAccordion from "./components/FaqAccordion";
import IMAGES from "../../../../assets/images";

import "./FAQ.css";

function FAQ() {
  return (
    <div className="faq-section-container">
      <div className="faq-section">
        <div className="">
          <Box
            component="img"
            className="planet-img-faq"
            id="planet-img3"
            src={IMAGES["planet3"]}
          />

          <Box
            component="img"
            className="planet-img-faq"
            id="planet-img7"
            src={IMAGES["planet7"]}
          />
        </div>
        <Box
          sx={{
            p: 3,
          }}
        >
          <Typography variant="h2TitleFont">FAQ</Typography>
          {faqList.confirmed ? (
            faqList.questions.map((faq, index) => {
              return (
                <FaqAccordion
                  key={index}
                  answer={faq.answer}
                  question={faq.question}
                />
              );
            })
          ): (
            <Typography variant="body1">
              Coming soon! Stay tuned for more information.
            </Typography>
          )}
        </Box>
      </div>
    </div>
  );
}

export default React.memo(FAQ);
