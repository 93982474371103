import React, { useContext, useState } from "react";
import { TextField, Typography } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import IMAGES from "../../../../../assets/images";
import "./Submission.css"
import { submitGame } from "../../../../../apis/team";
import { LoginContext } from "../../../../../hooks/LoginProvider";

type Props = {
    teamId: string;
    submissionLink?: string;
    openSnackbar: (severity: "error" | "success", message: string) => void;
};

const textFieldBorderColor = "#AAAAAA"

function Submission({ teamId, submissionLink, openSnackbar }: Props) {
    const { accessToken, setLogin } = useContext(LoginContext);
    const matches = submissionLink?.match(/https:\/\/([a-z0-9-]{2,25})\.itch\.io\/([a-z0-9-]{2,25})/);
    const [username, setUsername] = useState((matches && matches[1]) || "");
    const [gameName, setGameName] = useState((matches && matches[2]) || "");
    const [isSubmitting, setSubmitting] = useState(false);

    const handleUsernameChange = (event: { target: { value: string; }; }) => {
        const cleanedValue = event.target.value.replace(/[^a-z0-9-]/gi, '');
        setUsername(cleanedValue);
    }

    const handleGameNameChange = (event: { target: { value: string; } }) => {
        const cleanedValue = event.target.value.replace(/[^a-z0-9-]/gi, '');
        setGameName(cleanedValue);
    }

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        setSubmitting(true);

        await submitGame(
            {
                teamId: teamId,
                username: username,
                gameName: gameName,
            },
            accessToken,
            setLogin
        ).then(() => openSnackbar("success", "Submission successfully updated!"))
            .catch(
                err => {
                    const submissionExists = err.submissionExists;
                    if (submissionExists) {
                        openSnackbar("error", "There was an error updating the submission. Please try again later.");
                    } else {
                        openSnackbar("error", "The submission could not be found. Please check your username and game name again.");
                    }
                }
            ).finally(() => setSubmitting(false));
    }

    const formContainer = () => (
        <div style={{ padding: 10 }}>
            <TextField
                fullWidth
                label="Itch.io Username"
                onChange={handleUsernameChange}
                variant="outlined"
                value={username}
                required
                style={{ marginTop: "15px", marginBottom: "20px" }}
                sx={{
                    "& .MuiOutlinedInput-root": {
                        "& > fieldset": { borderColor: textFieldBorderColor, borderRadius: "10px" },
                    },
                }}
                inputProps={{
                    style: { fontSize: "1rem" },
                }}
            />

            <TextField
                fullWidth
                label="Itch.io Game Name"
                onChange={handleGameNameChange}
                variant="outlined"
                value={gameName}
                required
                style={{ marginBottom: "5px" }}
                sx={{
                    "& .MuiOutlinedInput-root": {
                        "& > fieldset": { borderColor: textFieldBorderColor, borderRadius: "10px" },
                    },
                }
                }
                inputProps={{
                    style: { fontSize: "1rem" },
                }}
            />

            <div style={{ marginBottom: "20px" }}>
                <Typography variant="subtitle2" align="left">
                    The username and game name fields should be filled out based on the URL of your team's game on itch.io, which should have the format:&nbsp;
                    <u>https://[username].itch.io/[game-name]</u>.
                    <br />
                    For more information on submission guidelines, check the&nbsp;
                    <a href="/#faq-section" target="_blank">FAQ</a>.
                </Typography>
            </div>

            <LoadingButton
                loading={isSubmitting}
                variant="contained"
                type="submit"
                color="secondary"
            >
                Submit
            </LoadingButton>
        </div >
    )

    return (
        <form onSubmit={handleSubmit}>
            <div className="submission">
                <div className="header-sub-container">
                    <img
                        className="ufo-submission"
                        src={IMAGES["ufo"]}
                        alt="UFO"
                    />
                    <Typography variant="h3TitleFont">
                        <u className="header-sub"> SUBMISSION </u>
                    </Typography>
                </div>

                {formContainer()}
            </div>
        </form >
    );
}

export default React.memo(Submission);
