import { Grid } from "@mui/material";
import React from "react";
import { EventDetails } from "../../../../../types/types";
import EventScheduleDetail from "./EventScheduleDetail";

interface Props {
    eventSchedule?: Array<EventDetails>;
}

function EventScheduleGrid({ eventSchedule }: Props) {
    return (
        <Grid
            container
            columns={12}
            spacing={2}
            maxWidth={{ xs: 400, sm: 500, md: 600, lg: 800 }}
        >
            {eventSchedule?.map((item, index) => {
                return (
                    <Grid item key={index} xs={12} sm={12}>
                        <EventScheduleDetail eventDetail={item} />
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default React.memo(EventScheduleGrid);
