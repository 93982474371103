import React from "react";
import aboutList from "../../../../dataFiles/About.json";
import CircleIcon from "@mui/icons-material/Circle";
import { Grid, Typography } from "@mui/material";
import IMAGES from "../../../../assets/images";
import SanitizeHtml from "../../../../components/SanitizeHtml";
import "./About.css";
import { ThemeContext } from "@mui/styled-engine";


export default function About() {
    const theme: any = React.useContext(ThemeContext);

    const renderAboutItem = (aboutItem: any) => {
        return (
            <Grid item xs={12} md={12} lg={6}>
                <Typography variant="h3" style={{ marginBottom: "1em" }}>
                    {aboutItem.title}
                </Typography>
                {aboutItem.list.map((point: string) => {
                    return (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                marginBottom: "0.6em",
                                paddingLeft: "1em",
                                justifyContent: "center",
                            }}
                        >
                            <CircleIcon
                                sx={{
                                    fontSize: "0.7em",
                                    marginTop: "0.4em",
                                    marginRight: "0.8em",
                                }}
                            />
                            <SanitizeHtml
                                dirtyHtml={point}
                                style={{
                                    marginLeft: "1em",
                                    textAlign: "left",
                                    fontFamily: theme.typography.fontFamily,
                                    ...theme.typography.body1,
                                    // width: "100%",
                                }}
                            />
                        </div>
                    );
                })}
            </Grid>
        );
    };

    return (
        <div className="about-top-container">
            <Typography variant="h2TitleFont" sx={{ marginBottom: "0.8em" }}>
                About
            </Typography>
            <Grid container columns={12} rowSpacing={7} justifyContent="center">
                {aboutList.map((about) => {
                    return renderAboutItem(about);
                })}
                <img className="moon-img" src={IMAGES["moon"]} alt="" />
            </Grid>
        </div>
    );
}
