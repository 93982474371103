import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import IMAGES from "../../../../../assets/images";
import JudgeDetailsPopup from "./JudgeDetailsPopup";
import { JudgeDetails } from "../../../../../constants/judgeInfo";

interface Props {
    judgeDetails: JudgeDetails;
}

function JudgeInfo({ judgeDetails }: Props) {
    const [isOpenDetailedPopup, setIsOpenDetailedPopup] = React.useState(false);

    return (
        <div>
            <Box style={styles.judgeItem}>
                <img
                    src={IMAGES[judgeDetails.imgPath]}
                    alt={judgeDetails.name}
                    style={styles.judgeImg}
                />
                <Box
                    sx={{
                        // marginLeft: "2rem",
                        display: "flex",
                        flexDirection: "column",
                        width: "250px",
                    }}
                >
                    <Typography color="secondary" variant="h4TitleFont">
                        {judgeDetails.name}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        style={styles.textDescription}
                    >
                        {judgeDetails.jobTitle}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={[styles.textDescription, { marginTop: "0.3rem" }]}
                    >
                        {judgeDetails.company}
                    </Typography>
                </Box>
                <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                    onClick={() => setIsOpenDetailedPopup(true)}
                    sx={{ marginTop: 0, marginBottom: "auto", padding: 0 }}
                >
                    <InfoOutlinedIcon />
                </IconButton>
            </Box>
            <JudgeDetailsPopup
                isOpen={isOpenDetailedPopup}
                onClose={() => setIsOpenDetailedPopup(false)}
                judgeDetails={judgeDetails}
            />
        </div>
    );
}

export default React.memo(JudgeInfo);

const styles: { [key: string]: React.CSSProperties } = {
    judgeImg: {
        width: 120,
        height: 120,
        borderRadius: "50%",
        objectFit: "cover",
    },
    judgeItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        margin: 10,
        backdropFilter: "blur(2px)",
        background: "#08376F64",
        borderRadius: "10px",
        padding: "10px",
    },
    judgeDescription: {
        textAlign: "center",
        width: "100%",
    },
};
