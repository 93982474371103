import React, { useState } from "react";
import { Box, Typography, Table, TableContainer, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import { useEventInfo } from "../../../../../hooks/EventInfoProvider";
import { User } from "../../../../../types/types";
import AddMember from "./AddMember";
import LeaveTeamButton from "./LeaveTeamButton";
import MemberEntry from "./MemberEntry";

type Props = {
    members?: User[];
    leader: string;
    isLeader: boolean;
    teamId: string;
    leaveTeam: () => Promise<void>;
    openSnackbar: (severity: "error" | "success", message: string) => void;
};

const MemberList = ({ members, leader, isLeader, teamId, leaveTeam, openSnackbar }: Props) => {
    const [memberList, setMemberList] = useState<User[]>(members || []);
    const eventInfo = useEventInfo();
    const maxTeamSize = eventInfo?.maxTeamSize || 1;
    const canLeave = !isLeader || memberList.length === 1;

    const kickMember = (email: string) => {
        setMemberList(memberList.filter(user => user.email !== email));
    };

    const memberTable = (
        <TableContainer sx={{ padding: 3, paddingTop: 0 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="h5">Name</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="h5">Tags</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="h5">Discord</Typography>
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {memberList.map(member => (
                        <MemberEntry 
                            member={member} 
                            isLeader={member.email === leader} 
                            canManage={isLeader}
                            kickMember={() => kickMember(member.email)}
                            teamId={teamId}
                            key={member.email}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    return (
        <Box>
            {memberTable}
            <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
                {
                    isLeader && memberList.length < maxTeamSize &&
                    <AddMember teamId={teamId} openSnackbar={openSnackbar} />
                }
                {canLeave && <LeaveTeamButton leaveTeam={leaveTeam} />}
            </div>            
        </Box>
    );
};

export default React.memo(MemberList);
