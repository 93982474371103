import React from "react";

import Rules from "./RulesSection";

import ContactUs from "../MainPage/sections/ContactUs";

import ShootingStar from "../../components/ShootingStar/ShootingStar";
import Rocket from "../../components/Rocket/Rocket";

import IMAGES from "../../assets/images";

import "./RulesPage.css";
import { Container } from "@mui/material";

const RulesPage = () => {
  return (
    <>
      <div>
        <img
          className="astronaut1"
          src={IMAGES["astronaut"]}
          alt="GameCraft Main Logo"
        />
        <img
          className="astronaut2"
          src={IMAGES["astronaut"]}
          alt="GameCraft Main Logo"
        />
      </div>
      <div className="stars-container">
        <ShootingStar delay={0} timing={3} top={"10%"} left={"5vw"} />
        <ShootingStar delay={6} timing={3} top={"50%"} left={"10vw"} />
        <ShootingStar delay={4} timing={3} top={"90%"} left={"30vw"} />
        <ShootingStar delay={5} timing={3} top={"130%"} left={"20vw"} />
      </div>
      <div className="rocket-container">
        <Rocket delay={0} timing={10} top={"100vh"} left={"-20vh"} />
      </div>
      <div className="mainpage">
        <Container maxWidth="xl">
          <section style={{ marginTop: "2em" }} id="rules-section">
            {/* Back button */}
            <div className="back-button-container" style={styles.backBtn}>
              <a href="/">
                <i className="fas fa-arrow-left"></i>
                <span style={{ marginLeft: "0.5em" }}>Back</span>
              </a>
            </div>

            <Rules />

            {/* Back button */}
            <div className="back-button-container" style={styles.backBtn}>
              <a href="/">
                <i className="fas fa-arrow-left"></i>
                <span style={{ marginLeft: "0.5em" }}>Back</span>
              </a>
            </div>
          </section>
          <section id="contact-us-section" style={styles.subSectionSpacing}>
            <ContactUs />
          </section>
          <section style={{ ...styles.subSectionSpacing, display: "flex" }} />
        </Container>
      </div>
    </>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  subSectionSpacing: {
    marginTop: "4em",
  },
  backBtn: {
    textAlign: "left",
    marginBottom: "2em",
    marginLeft: "2em",
    fontSize: "1.2em",
    textDecoration: "underline",
  },
};

export default React.memo(RulesPage);
