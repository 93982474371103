import React from "react";

import { ThemeContext } from "@mui/styled-engine";
import {
    List,
    ListItemButton,
    Typography,
    Drawer as MuiDrawer,
} from "@mui/material";
import { Link, animateScroll as scroll } from "react-scroll";
import { useNavigate, useLocation } from "react-router-dom";

interface Props {
    /**
     * Double array to store the navigation section links
     * [Section Name, Section id]
     */
    navItems: string[][];

    isDrawerOpen: boolean;

    isSamePageNavigation: boolean;

    handleDrawerClose: () => void;

    /**
     * Component to be placed at the top of the drawer
     */
    drawerTopComponent?: React.ReactNode;

    /**
     * Component to be placed at the bottom of the drawer
     */
    drawerBottomComponent?: React.ReactNode;

    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;

    /**
     * display setting of navBar, for responsive breakpoints
     */
    drawerDisplay?: any;

    /**
     * drawer background color, if not specified, default color is used
     */
    drawerBackgroundColor?: string;

    /**
     * drawer width, if not specified, default color is used
     */
    drawerWidth?: number;
}

const DEFAULT_DRAWER_BACKGROUND_COLOR = "#2F5793"; //2F5793, DRAWER BACKGROND COLOR";
const DEFAULT_DRAWER_WIDTH = 240;

function Drawer(props: Props) {
    const theme: any = React.useContext(ThemeContext);
    const { window } = props;
    const container =
        window !== undefined ? () => window()?.document.body : undefined;

    const [activeSectionIndex, setActiveSectionIndex] = React.useState(0);

    const location = useLocation();
    const nav = useNavigate();
    const navigate = (to: string) => {
        // navigate at the top of the page
        props.handleDrawerClose();
        scroll.scrollToTop({ duration: 0 });
        nav(to);
    };

    const headerButtonStyles = {
        activeSectionMobile: {
            color: theme.palette.secondary.main,
            fontFamily: theme.typography.fontFamily,
            ...theme.typography.h5,
        },
        inactiveSectionMobile: {
            color: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamily,
            ...theme.typography.body1,
        },
    };

    const renderDrawerTextButton = (
        index: number,
        sectionId: string,
        sectionName: string
    ) => {
        return (
            <Link
                activeClass="active"
                to={sectionId}
                spy
                smooth
                offset={-50}
                duration={500}
                onSetActive={() => {
                    setActiveSectionIndex(index);
                }}
                style={{ width: "100%" }}
                onClick={() => {
                    if (!props.isSamePageNavigation) {
                        navigate(sectionId);
                    }
                }}
            >
                <Typography
                    variant="body1"
                    sx={
                        (!props.isSamePageNavigation &&
                            location.pathname === sectionId) ||
                        (props.isSamePageNavigation &&
                            activeSectionIndex === index)
                            ? headerButtonStyles.activeSectionMobile
                            : headerButtonStyles.inactiveSectionMobile
                    }
                    align="left"
                >
                    {sectionName}
                </Typography>
            </Link>
        );
    };

    const renderDrawerList = () => {
        return (
            <div>
                {props.navItems.map((item, index) => (
                    <ListItemButton key={index}>
                        {renderDrawerTextButton(index, item[1], item[0])}
                    </ListItemButton>
                ))}
            </div>
        );
    };

    return (
        <MuiDrawer
            container={container}
            variant="temporary"
            open={props.isDrawerOpen}
            onClose={props.handleDrawerClose}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: props.drawerDisplay,
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: props.drawerWidth ?? DEFAULT_DRAWER_WIDTH,
                    backgroundColor:
                        props.drawerBackgroundColor ??
                        DEFAULT_DRAWER_BACKGROUND_COLOR,
                },
            }}
        >
            {props.drawerTopComponent}
            <List>{renderDrawerList()}</List>
            {props.drawerBottomComponent}
        </MuiDrawer>
    );
}

export default React.memo(Drawer);
