import { Box, Button, Typography } from "@mui/material";
import React from "react";
import IMAGES from "../../assets/images";

interface Props {
    /** Current day number */
    currentDay: number;

    /** Whether the day has passed */
    isDayPassed: boolean;

    /** Whether user selected this day to see info */
    isSelected: boolean;

    /** Whether it's the current day */
    isCurrDay: boolean;

    /** Number of days to reach this day */
    daysLeftTillCurrDay: number;

    /** What happens when user select this day */
    handleSelectDay?: () => void;

    /** There's a day before this day to draw the left dotted line */
    hasPrevDay?: boolean;
}

const ROCKET_SIZE = { xs: 45, sm: 65, md: 100, lg: 120 };
const ROCKET_TRANSFORM = {
    xs: "translate(-10px, -10px)",
    sm: "translate(-10px, -15px)",
    md: "translate(-10px, -28px)",
    lg: "translate(-10px, -35px)",
};

const TEXT_TRANSFORM = {
    xs: "translate(0, 28px)",
    sm: "translate(0, 32px)",
    md: "translate(0, 44px)",
    lg: "translate(0, 52px)",
};

function DaySelector(props: Props) {
    const dayButtonStyle = props.isSelected
        ? styles.selectedVariant
        : props.isDayPassed || props.isCurrDay
        ? styles.dayHasPassedVariant
        : styles.incomingDayVariant;

    const isIncomingDay = !props.isDayPassed && !props.isCurrDay;

    const renderDayCounterText = () => {
        if (props.isCurrDay) {
            return (
                <Typography color="secondary" sx={styles.dayCounterText}>
                    Today!
                </Typography>
            );
        }

        if (props.isDayPassed) {
            return (
                <Typography color="secondary" sx={styles.dayCounterText}>
                    Day is over!
                </Typography>
            );
        }

        const pluralDayString = props.daysLeftTillCurrDay > 1 ? "days" : "day";

        return (
            <Typography color="secondary" sx={styles.dayCounterText}>
                In <strong>{props.daysLeftTillCurrDay}</strong>{" "}
                {pluralDayString}!
            </Typography>
        );
    };

    return (
        <Box sx={styles.mainContainer}>
            {props.hasPrevDay && (
                <Box
                    sx={[
                        styles.dottedLine,
                        isIncomingDay && styles.icomingDayDottedLine,
                    ]}
                />
            )}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {props.isCurrDay && (
                    <Box
                        component="img"
                        src={IMAGES["rocket"]}
                        sx={{
                            position: "absolute",
                            width: ROCKET_SIZE,
                            height: ROCKET_SIZE,
                            zIndex: 0,
                            transform: ROCKET_TRANSFORM,
                        }}
                    />
                )}
                <Button
                    variant="outlined"
                    onClick={props.handleSelectDay}
                    sx={[styles.dayContainer, dayButtonStyle]}
                >
                    <Typography
                        sx={{
                            typography: {
                                xs: "subtitle1",
                                sm: "body1",
                                md: "h4",
                                lg: "h3",
                            },
                        }}
                    >
                        {props.currentDay}
                    </Typography>
                    {props.isDayPassed && (
                        <Box
                            sx={[
                                styles.strike,
                                props.isSelected && {
                                    backgroundColor: "orange",
                                },
                            ]}
                        />
                    )}
                </Button>
                {props.isSelected && (
                    <Box
                        sx={{ position: "absolute", transform: TEXT_TRANSFORM }}
                    >
                        {renderDayCounterText()}
                    </Box>
                )}
            </Box>
        </Box>
    );
}

const SIZE = { xs: 30, sm: 40, md: 60, lg: 70 };
const BORDER_WIDTH = { xs: 2, sm: 3, md: 4, lg: 5 };
const LINE_LENGTH = { xs: 14, sm: 36, md: 50, lg: 70 };
const PRIMARY_COLOR = "white";
const SELECTED_COLOR = "#ffad00";
const TRANSPARENT_PRIMARY_COLOR = "rgba(255,255,255,0.3)";
const styles = {
    mainContainer: {
        display: "flex",
        flexDirection: "row",
        height: SIZE, //should be same height as dayContainer
        alignItems: "center",
    },

    //For button day container
    dayContainer: {
        borderRadius: "50%", //make into circle
        height: SIZE,
        width: SIZE,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderWidth: "10px",
        zIndex: 10,
        //hack to overwrite button attributes
        minWidth: 0,
        padding: 0,
    },
    dayHasPassedVariant: {
        //normal color
        borderWidth: BORDER_WIDTH,
        borderColor: PRIMARY_COLOR,
        color: PRIMARY_COLOR,
        ":hover": {
            borderWidth: BORDER_WIDTH,
            borderColor: PRIMARY_COLOR,
            backgroundColor: TRANSPARENT_PRIMARY_COLOR,
        },
        ".MuiTouchRipple-child": {
            backgroundColor: PRIMARY_COLOR,
        },
    },
    selectedVariant: {
        //just selected color of orange
        borderWidth: BORDER_WIDTH,
        borderColor: SELECTED_COLOR,
        color: SELECTED_COLOR,
        ":hover": {
            borderWidth: BORDER_WIDTH,
            borderColor: SELECTED_COLOR,
            backgroundColor: TRANSPARENT_PRIMARY_COLOR,
        },
        ".MuiTouchRipple-child": {
            backgroundColor: SELECTED_COLOR,
        },
    },
    incomingDayVariant: {
        //should be slightly transparent
        borderWidth: BORDER_WIDTH,
        borderColor: TRANSPARENT_PRIMARY_COLOR,
        color: TRANSPARENT_PRIMARY_COLOR,
        ":hover": {
            borderWidth: BORDER_WIDTH,
            borderColor: TRANSPARENT_PRIMARY_COLOR,
            backgroundColor: TRANSPARENT_PRIMARY_COLOR,
        },
        ".MuiTouchRipple-child": {
            backgroundColor: TRANSPARENT_PRIMARY_COLOR,
        },
    },

    //For the dotted lines
    dottedLine: {
        backgroundImage: "linear-gradient(to right, white 33%, transparent 0%)",
        height: SIZE,
        width: LINE_LENGTH, //how long the line should be
        backgroundSize: "15px 3px", //dash width, height
        backgroundRepeat: "repeat-x",
        backgroundPosition: "center",
    },
    icomingDayDottedLine: {
        backgroundImage:
            "linear-gradient(to right, rgba(255,255,255,0.3) 33%, transparent 0%)",
    },

    strike: {
        transform: "rotate(45deg)",
        backgroundColor: PRIMARY_COLOR,
        width: BORDER_WIDTH,
        height: SIZE,
        position: "absolute",
        borderRadius: 2,
    },

    dayCounterText: {
        fontSize: {
            xs: "0.6rem",
            sm: "0.8rem",
            md: "1rem",
            lg: "1.05rem",
        },
    },
};

export default React.memo(DaySelector);
