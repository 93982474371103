import * as React from "react";

import { animateScroll as scroll } from "react-scroll";
import {
    Button,
    AppBar,
    Box,
    Divider,
    ListItem,
    ListItemButton,
    Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useLocation } from "react-router-dom";
import { useLogin } from "../../hooks/LoginProvider";
import { useEventInfo } from "../../hooks/EventInfoProvider";

import Drawer from "./components/Drawer";
import StaticNavBar from "./components/NavBar";
import LogoutButton from "./components/LogoutButton";

import IMAGES from "../../assets/images";

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const LANDING_PAGE_NAV_ITEMS = [
    ["About", "about-section"],
    ["FAQ", "faq-section"],
    ["Schedule", "schedule-section"],
    ["Judging", "judging-section"],
    ["Past Jams", "past-jams-section"],
    ["Prizes", "prizes-section"],
    ["Partners", "partners-section"],
    ["Contact", "contact-us-section"],
];

const LOGGED_IN_NAV_ITEMS = [
    ["Dashboard", "/dashboard"],
    ["Schedule", "/schedule"],
];

const BACK_TO_HOME_NAV_ITEMS = [["Home", "/"]];

const DRAWER_DISPLAY_SETTINGS = { md: "none" };
const NAV_BAR_DISPLAY_SETTINGS = { xs: "none", md: "block" };

function DrawerAppBar(props: Props) {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const { accessToken, setLogin } = useLogin();
    const eventInfo = useEventInfo();
    const location = useLocation();
    const nav = useNavigate();
    const navigate = (to: string) => {
        scroll.scrollToTop({ duration: 0 });
        setMobileOpen(false);
        nav(to);
    };

    const getNavItemsViaCurrLocationPath = (): string[][] => {
        const nonLandingPgNavItems = isCurrPathInLoggedInView()
            ? [...BACK_TO_HOME_NAV_ITEMS, ...LOGGED_IN_NAV_ITEMS]
            : BACK_TO_HOME_NAV_ITEMS;

        return location.pathname === "/"
            ? LANDING_PAGE_NAV_ITEMS
            : nonLandingPgNavItems;
    };

    const isCurrPathInLoggedInView = (): boolean => {
        return LOGGED_IN_NAV_ITEMS.map((item) => item[1]).includes(
            location.pathname
        );
    };

    const navigateToHome = () => {
        navigate("/");
    };

    /** renders either the login, dashboard or logout button depending on whether user is logged in and which page */
    const drawerLoginDashboardButton = () => {
        return eventInfo?.allowLogin && (
            <ListItem key="login" disablePadding>
                <ListItemButton
                    sx={{
                        textAlign: "center",
                        marginTop: "2rem",
                    }}
                >
                    {isCurrPathInLoggedInView() ? (
                        <LogoutButton
                            setLogin={setLogin}
                            nav={navigate}
                            style={styles.drawerButton}
                        />
                    ) : (
                        <Button
                            variant="contained"
                            color="secondary"
                            sx={styles.drawerButton}
                            onClick={() => {
                                navigate(accessToken ? "/dashboard" : "/login");
                            }}
                        >
                            {!accessToken ? "Login" : "Dashboard"}
                        </Button>
                    )}
                </ListItemButton>
            </ListItem>
        );
    };

    /** renders either the login, dashboard or logout button depending on whether user is logged in and which page */
    const navBarButton = () => {
        return eventInfo?.allowLogin && (
            <Box m={1} sx={{ cursor: "pointer" }}>
                {isCurrPathInLoggedInView() ? (
                    <LogoutButton
                        setLogin={setLogin}
                        nav={navigate}
                        requiresConfirmation
                        style={styles.navBarButton}
                    />
                ) : (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            navigate(!accessToken ? "/login" : "/dashboard");
                        }}
                        sx={styles.navBarButton}
                    >
                        {accessToken ? "Dashboard" : "Login"}
                    </Button>
                )}
            </Box>
        );
    };

    const drawerHeaderIcon = () => {
        return (
            <Box sx={{ textAlign: "center" }}>
                <img
                    src={IMAGES.gamecraftIconWhite}
                    alt="Gamecraft Icon"
                    style={styles.gamecraftIconDrawer}
                    onClick={navigateToHome}
                />

                <Typography
                    variant="h4TitleFont"
                    sx={{
                        my: 2,
                        color: "white",
                        cursor: "pointer",
                    }}
                    onClick={navigateToHome}
                >
                    GameCraft
                </Typography>
                <Divider style={{ marginTop: "1rem" }} />
            </Box>
        );
    };

    const navBarHeaderIcon = () => {
        return (
            <>
                <img
                    src={IMAGES.gamecraftIconWhite}
                    alt="Gamecraft Icon"
                    style={styles.gamecraftIconFull}
                    onClick={navigateToHome}
                />
                <Typography
                    variant="h6"
                    component="div"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        flexGrow: 1,
                        cursor: "pointer",
                    }}
                    onClick={navigateToHome}
                >
                    GameCraft
                </Typography>
            </>
        );
    };

    const renderHamburgerIcon = () => {
        return (
            <Button
                color="secondary"
                variant="contained"
                onClick={() => setMobileOpen(true)}
                sx={{
                    display: DRAWER_DISPLAY_SETTINGS,
                    ...styles.hamburgerButton,
                }}
            >
                <MenuIcon />
            </Button>
        );
    };

    const renderNavBar = () => {
        const navItems = getNavItemsViaCurrLocationPath();

        return (
            <StaticNavBar
                navItems={navItems}
                navBarStartComponent={navBarHeaderIcon()}
                isSamePageNavigation={location.pathname === "/"}
                navBarBackComponent={
                    location.pathname !== "/login" && navBarButton()
                }
                navBarDisplay={NAV_BAR_DISPLAY_SETTINGS}
            />
        );
    };

    const renderDrawer = () => {
        const navItems = getNavItemsViaCurrLocationPath();

        return (
            <Drawer
                navItems={navItems}
                isDrawerOpen={mobileOpen}
                handleDrawerClose={() => setMobileOpen(false)}
                isSamePageNavigation={location.pathname === "/"}
                drawerBottomComponent={
                    location.pathname !== "/login" &&
                    drawerLoginDashboardButton()
                }
                drawerTopComponent={drawerHeaderIcon()}
                drawerDisplay={DRAWER_DISPLAY_SETTINGS}
                window={props.window}
            />
        );
    };

    return (
        <Box sx={{ display: "flex" }}>
            <AppBar color="transparent" elevation={0}>
                {renderHamburgerIcon()}
            </AppBar>

            {renderNavBar()}
            {renderDrawer()}

            {/* prevent navbar from overlapping with content, add some padding */}
            <Box component="main" sx={{ p: 3 }}></Box>
        </Box>
    );
}

export default React.memo(DrawerAppBar);

const styles: { [key: string]: React.CSSProperties } = {
    gamecraftIconDrawer: {
        height: "4em",
        margin: "auto",
        marginTop: "20px",
        cursor: "pointer",
    },
    gamecraftIconFull: {
        height: "2em",
        margin: "auto",
        paddingRight: "10px",
        cursor: "pointer",
    },

    drawerButton: {
        marginRight: "auto",
        marginLeft: "auto",
        width: "100%",
    },
    navBarButton: {
        padding: "2px 16px 2px 16px",
        marginLeft: "0.5rem",
    },
    hamburgerButton: {
        padding: 0,
        maxWidth: "30px",
        maxHeight: "30px",
        minWidth: "30px",
        minHeight: "30px",
        marginLeft: "1em",
        marginTop: "1em",
    },
};
