import axios from "axios";

import { Button, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { memo, useState, useEffect, useCallback } from "react";

import IMAGES from "../../assets/images";

import "./Login.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { SERVER_LINK } from "../../apis/constant";

import { useLogin } from "../../hooks/LoginProvider";

type Props = {
    willNavigateOnSuccess?: boolean;
};

function Login({ willNavigateOnSuccess = true }: Props) {

    const { accessToken, setLogin } = useLogin();
    const nav = useNavigate();
    const navigate = (to: string) => {
        // navigate at the top of the page
        scroll.scrollToTop({duration: 0});
        nav(to);
    }

    const [isSubmitting, setSubmitting] = useState(false);
    const [loginStatus, setSuccessStatus] = useState({
        init: false,
        status: false,
    });
    const [errorMessage, setErrorMessage] = useState(
        "Incorrect username/password."
    );
    const [resendVerification, setResendVerification] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [resetButton, setResetButton] = useState(true);

    // Auto redirect user to /dashboard if already logged in.
    const init = useCallback(async () => {
        try {
            if (accessToken !== "") {
                willNavigateOnSuccess && navigate("/dashboard");
            }
        } catch (err) {
            console.log(err);
        }
    }, [accessToken]);

    // Triggered when accesstToken is changed.
    useEffect(() => {
        init();
    }, [accessToken])

    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    // Init user
    useEffect(() => {

        init();

        axios
            .post(SERVER_LINK + "/refresh", null, { withCredentials: true })
            .then(async (response: any) => {
                if (
                    response.data.data.accessToken &&
                    response.data.data.email
                ) {
                    setLogin(
                        response.data.data.accessToken,
                        response.data.data.email
                    );
                }
            })
            .catch(function (error: any) {
                console.log(error);
            });

        if (searchParams.get("verified")) {
            setSuccessStatus({ init: true, status: true });
            setSuccessMessage(
                "Your account has been successfully verified. Please proceed to login."
            );
        }
    }, []);

    const resendLink = () => {
        // Resend verification link
        if (resetButton) {
            setResetButton(false);

            setSuccessStatus({ init: true, status: true });
            setSuccessMessage("Resending verification email...");

            axios
                .post(SERVER_LINK + "/reverify", formData, {
                    withCredentials: true,
                })
                .then((response: any) => {
                    if (response.status === 200) {
                        setSuccessStatus({ init: true, status: true });
                        setSuccessMessage(
                            "Verification email has been resent."
                        );
                    }
                })
                .catch((error: any) => {
                    setSuccessStatus({ init: true, status: false });
                    setErrorMessage(
                        "Too many requests. Please try again later."
                    );
                });
        }
    };

    const handleSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault();
        setSubmitting(true);

        formData.email = formData.email.toLowerCase();

        // Login
        axios
            .post(SERVER_LINK + "/login", formData, { withCredentials: true })
            .then((response: any) => {
                if (response.data.data.accessToken) {
                    setLogin(
                        response.data.data.accessToken,
                        response.data.data.email
                    );
                    setSuccessStatus({ init: true, status: true });
                    setSuccessMessage(
                        "Successfully logged in. Redirecting to dashboard."
                    );
                    willNavigateOnSuccess && navigate("/dashboard");
                }
            })
            .catch((error: any) => {
                console.log(error);
                if (error.response.status === 429) {
                    setErrorMessage(
                        "Too many login attempts. Please try again later."
                    );
                    setSuccessStatus({ init: true, status: false });
                }
                if (error.response.status === 403) {
                    setErrorMessage(
                        "Please verify your account before logging in."
                    );
                    setResendVerification(true);
                    setSuccessStatus({ init: true, status: false });
                }
                if (error.response.status === 404 || error.response.status === 401 || error.response.status === 400) {
                    setErrorMessage("Incorrect username/password.");
                    setSuccessStatus({ init: true, status: false });
                }
            }).finally(() => setSubmitting(false));
    }

    return (
        <div id="loginFormBg">
            <form id="loginForm" onSubmit={handleSubmit}>
                <Typography variant="h2TitleFont">LOG IN</Typography>
                <div
                    className="auth_feedback auth_success"
                    style={
                        loginStatus.init && loginStatus.status
                            ? styles.displayBlock
                            : styles.displayNone
                    }
                >
                    <h4>{successMessage}</h4>
                </div>
                <div
                    className="auth_feedback auth_failure"
                    style={
                        loginStatus.init && !loginStatus.status
                            ? styles.displayBlock
                            : styles.displayNone
                    }
                >
                    <h4>{errorMessage}</h4>
                </div>
                <a
                    href=""
                    className="highlight_btn"
                    onClick={(e) => {
                        e.preventDefault();
                        resendLink();
                    }}
                    style={
                        resendVerification
                            ? styles.displayBlock
                            : styles.displayNone
                    }
                >
                    Resend Verification Link
                </a>
                <div className="login">
                    <TextField
                        fullWidth
                        value={formData.email}
                        name="email"
                        onChange={(e) => {
                            setFormData({ ...formData, email: e.target.value });
                        }}
                        required
                        placeholder="Email"
                        sx={{
                            "& fieldset": { border: "none" },
                            marginBottom: "1em",
                        }}
                        inputProps={{
                            style: styles.inputText,
                        }}
                    />
                    <TextField
                        fullWidth
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={(e) => {
                            setFormData({
                                ...formData,
                                password: e.target.value,
                            });
                        }}
                        required
                        placeholder="Password"
                        sx={{
                            "& fieldset": { border: "none" },
                        }}
                        inputProps={{
                            style: styles.inputText,
                        }}
                    />
                    <Typography
                        sx={{
                            textDecoration: "underline",
                            color: "#aebbd1",
                            marginRight: "0",
                            marginLeft: "auto",
                        }}
                    >
                        <a href="/reset">Forgot Password</a>
                    </Typography>
                    <LoadingButton
                        fullWidth
                        loading={isSubmitting}
                        variant="contained"
                        type="submit"
                        color="secondary"
                    >
                        Login
                    </LoadingButton>
                </div>
                <div className="subactions">
                    <Typography
                        color="secondary"
                        sx={{ textDecoration: "underline" }}
                    >
                        <a href="/register">Sign up</a>
                    </Typography>
                </div>
            </form>
            <img className="alien2" src={IMAGES["alien2"]} alt="Alien" />
        </div>
    );
}

const styles: { [key: string]: React.CSSProperties } = {
    displayBlock: {
        display: "block",
    },
    displayNone: {
        display: "none",
    },

    inputText: {
        fontSize: "1rem",
    },
};

export default memo(Login);
