import { CircularProgress, Typography, Box, Link } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { joinTeam } from "../../apis/team";
import IMAGES from "../../assets/images";
import { useLogin } from "../../hooks/LoginProvider";
import "./styles.css";

const JoinTeam = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>(
        "Something went wrong. Ask the team leader to resend the invite, or ask our staff for help!"
    );
    const { teamId } = useParams();
    const { accessToken, setLogin } = useLogin();
    const nav = useNavigate();

    const init = useCallback(async () => {
        if (!teamId || !accessToken || !setLogin) {
            return;
        }

        try {
            await joinTeam({ teamId: teamId }, accessToken, setLogin);
            nav("/dashboard");
        } catch (e) {
            setErrorMessage(String(e));
        } finally {
            setIsLoading(false);
        }
    }, [teamId, accessToken, setLogin]);

    useEffect(() => {
        init()
    }, [init]);

    return (
        <div className="join-team">
            {isLoading ? (
                <Box>
                    <Typography variant="h2">
                        If you're not logged in, please log in <Link href="/login">here</Link> first then click the link again!
                    </Typography>
                    <CircularProgress sx={{ margin: 10 }}/>
                </Box>
            ) : (
                <Typography variant="h2">
                    {errorMessage}
                </Typography>
            )}
        </div>
    );
}

export default React.memo(JoinTeam);
