import React from "react";
import rulesList from "../../../dataFiles/Rules.json";
import { Box, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { ThemeContext } from "@mui/styled-engine";

import "./Rules.css";
import SanitizeHtml from "../../../components/SanitizeHtml";

const Rules = () => {
    const theme: any = React.useContext(ThemeContext);

    const renderParagraphs = (
        idx3: React.Key | null | undefined,
        lines: any
    ) => {
        return (
            <Box key={idx3} style={styles.ruleParagraph}>
                {lines.content.map(
                    (line: string | null | undefined, idx4: any) => {
                        return (
                            <SanitizeHtml
                                dirtyHtml={line ?? ""}
                                style={{
                                    marginLeft: 10,
                                    textAlign: "left",
                                    fontFamily: theme.typography.fontFamily,
                                    ...theme.typography.body1,
                                }}
                            />
                        );
                    }
                )}
            </Box>
        );
    };

    const renderUnorderedList = (
        idx3: React.Key | null | undefined,
        lines: any
    ) => {
        return (
            <Box key={idx3} style={styles.ruleBullet}>
                {lines.content.map(
                    (line: string | null | undefined, idx4: any) => {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginBottom: "0.6em",
                                    paddingLeft: "1em",
                                    justifyContent: "left",
                                }}
                            >
                                <CircleIcon
                                    sx={{
                                        fontSize: "0.7em",
                                        marginTop: "0.4em",
                                        marginRight: "0.8em",
                                    }}
                                />
                                <SanitizeHtml
                                    dirtyHtml={line ?? ""}
                                    style={{
                                        marginLeft: 10,
                                        textAlign: "left",
                                        fontFamily: theme.typography.fontFamily,
                                        ...theme.typography.body1,
                                    }}
                                />
                            </div>
                        );
                    }
                )}
            </Box>
        );
    };

    const renderOrderedList = (
        idx3: React.Key | null | undefined,
        lines: any
    ) => {
        return (
            <Box key={idx3} style={styles.ruleList}>
                {lines.content.map(
                    (line: string | null | undefined, idx4: any) => {
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginBottom: "0.6em",
                                    paddingLeft: "1em",
                                    justifyContent: "left",
                                }}
                            >
                                <Typography
                                    align="left"
                                    variant="body1"
                                    style={{
                                        marginRight: "0.8em",
                                    }}
                                >
                                    {idx4 + 1}.
                                </Typography>
                                <SanitizeHtml
                                    dirtyHtml={line ?? ""}
                                    style={{
                                        marginLeft: 10,
                                        textAlign: "left",
                                        fontFamily: theme.typography.fontFamily,
                                        ...theme.typography.body1,
                                    }}
                                />
                            </div>
                        );
                    }
                )}
                <br />
            </Box>
        );
    };

    const renderRulesSubsection = (
        idx2: React.Key | null | undefined,
        sections: any
    ) => {
        return (
            <Box key={idx2} style={styles.ruleSubSection}>
                <Typography variant="h3">{sections.title}</Typography>
                <br />
                {sections.content.map(
                    (lines: any, idx3: React.Key | null | undefined) => {
                        if (lines.type === "paragraph") {
                            return renderParagraphs(idx3, lines);
                        } else if (lines.type === "bullet") {
                            // Return as bullet list
                            return renderUnorderedList(idx3, lines);
                        } else if (lines.type === "list") {
                            // Return as numbered list
                            return renderOrderedList(idx3, lines);
                        }
                    }
                )}
                <br />
            </Box>
        );
    };

    return (
        <>
            <div style={styles.rulesContainer} className="rules-container">
                {rulesList.map((data, idx) => {
                    return (
                        <Box key={idx} style={styles.ruleMainSection}>
                            <Typography variant="h2">{data.title}</Typography>
                            <br />
                            {data.content.map((sections, idx2) => {
                                return renderRulesSubsection(idx2, sections);
                            })}
                            <br />
                        </Box>
                    );
                })}
            </div>
        </>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    rulesContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        justifyContent: "left",
        textAlign: "left",
    },

    ruleMainSection: {
        width: "100%",
    },

    ruleSubSection: {
        width: "100%",
    },

    ruleParagraph: {
        width: "100%",
        paddingTop: "0.5em",
        paddingBottom: "0.5em",
    },

    ruleBullet: {
        width: "100%",
        paddingTop: "0.5em",
        paddingBottom: "0.5em",
    },

    ruleList: {
        width: "100%",
        paddingTop: "0.5em",
        paddingBottom: "0.5em",
    },
};

export default React.memo(Rules);
