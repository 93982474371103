import { Button, Typography, useMediaQuery } from "@mui/material";
import { useEventInfo } from "../../../../hooks/EventInfoProvider";

import IMAGES from "../../../../assets/images";
import "./HeaderSection.css";
import { MONTH } from "../../../../utils/dateTimeHelpers";

import { useLogin } from "../../../../hooks/LoginProvider";

export default function HeaderSection() {
    const { accessToken } = useLogin();

    const eventInfo = useEventInfo();

    const isExtraSmallScreen = useMediaQuery((theme: any) =>
        theme.breakpoints.down("xs")
    );
    const isSmallScreen = useMediaQuery((theme: any) =>
        theme.breakpoints.down("sm")
    );
    const isMediumScreen = useMediaQuery((theme: any) =>
        theme.breakpoints.down("md")
    );

    const getTimeInHeaderFormat = (date: Date): string => {
        const hour = date.getHours();
        const minutes = date.getMinutes();

        return `${hour}:${minutes < 10 ? "0" : ""}${minutes}`;
    };

    const getDateTimeInHeaderFormat = (date: Date | undefined): string => {
        if (!date) {
            return "";
        }

        return `${date.getDate()} ${
            MONTH[date.getMonth()]
        } ${getTimeInHeaderFormat(date)}`;
    };

    return (
        <>
            <div className="header-container">
                <div>
                    <img
                        className="logo-image"
                        src={IMAGES["gamecraftIconWhite"]}
                        alt="GameCraft Main Logo"
                    />
                </div>
                <div className="header-text-section">
                    <Typography variant="h1" style={{ marginBottom: "0.2em" }}>
                        GameCraft! 2024
                    </Typography>
                    <Typography variant="h2" style={{ marginBottom: "0.3em" }}>
                        {`${getDateTimeInHeaderFormat(
                            eventInfo?.eventStartDateTime
                        )} - ${getDateTimeInHeaderFormat(
                            eventInfo?.eventEndDateTime
                        )}`}
                    </Typography>
                    <div
                        className="text-section"
                        style={{ marginBottom: "1.8em" }}
                    >
                        <Typography variant="h3NoBold" display="block">
                            GameCraft! is NUSGDG’s annual game jam where
                            students come together and make a game in the span
                            of 1 week related to the theme!
                        </Typography>
                    </div>
                    <div style={{ display: eventInfo?.allowLogin ? "block" : "none" }}>
                        <div className="button-section">
                            <Button
                                variant="contained"
                                style={{
                                    display:
                                        isSmallScreen ||
                                        isExtraSmallScreen ||
                                        isMediumScreen
                                            ? "block"
                                            : "none",
                                    width: "100%",
                                    maxWidth: "200px",
                                    marginBottom: "2em",
                                    fontSize: "1.2rem",
                                }}
                                color="secondary"
                                component="a"
                                href={accessToken ? "/dashboard" : "/login"}
                            >
                                {accessToken ? "Dashboard" : "Login"}
                            </Button>
                        </div>
                        <div className="button-section">
                            <Button
                                variant={
                                    isSmallScreen ||
                                    isExtraSmallScreen ||
                                    isMediumScreen
                                        ? "outlined"
                                        : "contained"
                                }
                                color="secondary"
                                component="a"
                                href={eventInfo?.itchLink || ""}
                                target="_blank"
                            >
                                GameCraft! itch.io
                            </Button>
                            <Button
                                variant="outlined"
                                sx={styles.button}
                                color="secondary"
                                component="a"
                                href={eventInfo?.discordLink || ""}
                                target="_blank"
                            >
                                Join our Discord!
                            </Button>
                        </div>
                        <div className="text-section">
                            <Typography variant="caption" display="block">
                                Make sure to join our discord and check out our
                                itch.io page for submission!
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const styles = {
    button: {
        margin: 2,
    },
};
