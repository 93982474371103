import React, { useContext, useState } from "react";
import { Box, Popover, Button, IconButton, TableRow, TableCell, Typography, useMediaQuery } from "@mui/material";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import BadgeIcon from '@mui/icons-material/Badge';
import { User } from "../../../../../types/types";
import { removeMember } from "../../../../../apis/team";
import ProfileCard from "../../../../../components/ProfileCard";
import TagChip from "../../../../../components/TagChip";
import { LoginContext } from "../../../../../hooks/LoginProvider";

type Props = {
    member: User;
    isLeader: boolean;
    canManage: boolean;
    kickMember: () => void;
    teamId: string;
};

const MemberEntry = ({ member, isLeader, canManage, kickMember, teamId }: Props) => {
    const { accessToken, setLogin } = useContext(LoginContext);
    const smallScreen = useMediaQuery('(max-width:500px)');
    const { email } = member
    const [removeAnchor, setRemoveAnchor] = useState<HTMLButtonElement>();
    const [badgeAnchor, setBadgeAnchor] = useState<HTMLButtonElement>();

    const kickTeamMember = async () => {
        try {
            await removeMember({teamId: teamId, email: email}, accessToken, setLogin);
            kickMember();
        } catch (e) {
            console.log(e);
        }
    }

    const nameAndBadge = (
        <>
            <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                <Typography>{member.name}</Typography>
                <IconButton 
                    color="primary"
                    onClick={event => setBadgeAnchor(event.currentTarget)}
                >
                    <BadgeIcon />
                </IconButton>
            </div>
            <Popover
                open={!!badgeAnchor}
                anchorEl={badgeAnchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                onClose={() => setBadgeAnchor(undefined)}
                PaperProps={{
                    sx: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                    }
                }}
            >
                <ProfileCard user={member} width={smallScreen ? 200 : 400}/>   
            </Popover>
        </>
    )

    const removeMemberButton = (
        <>
            <IconButton
                color="error"
                onClick={event => setRemoveAnchor(event.currentTarget)}
            >
                <PersonRemoveIcon />
            </IconButton>
            <Popover
                open={!!removeAnchor}
                anchorEl={removeAnchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={() => setRemoveAnchor(undefined)}
            >
                <Box sx={{ backgroundColor: "black", padding: 2 }}>
                    Are you sure you want to remove this member?
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={() => setRemoveAnchor(undefined)}>
                            Cancel
                        </Button>
                        <Button color="error" onClick={kickTeamMember}>
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Popover>
        </>
    );

    return (
        <TableRow>
            <TableCell>{nameAndBadge}</TableCell>
            <TableCell>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {member.tags.map(tag => 
                        <div 
                            style={{ paddingRight: 5, paddingBottom: 5 }} 
                            key={member.email + tag}
                        >
                            <TagChip tag={tag} />
                        </div>
                    )}
                </div>
            </TableCell>
            <TableCell>
                <Typography>{member.discordName}</Typography>
            </TableCell>
            <TableCell>
                {isLeader 
                    ? <Typography color="lightgray">Leader</Typography>
                    : canManage && removeMemberButton
                }
            </TableCell>
        </TableRow>
    );
};

export default React.memo(MemberEntry);
