import { authDelete, authGet, authPut } from "../authFetch";
import { 
    GetUserResponse, 
    UpdateUserParams, 
    UpdateEventParams,
    VerifyUserParams,
    LoginDiscordParams,
} from "./types";
import { mapUserDataToUser, mapUserToUserData } from "./utils";
import { SERVER_LINK } from "../constant";

const GET_USER_URL = SERVER_LINK + "/user";
const UPDATE_USER_URL = SERVER_LINK + "/user";
const UPDATE_EVENTS_URL = SERVER_LINK + "/user/events";
const VERIFY_USER_URL = SERVER_LINK + "/verify";
const UNLINK_DISCORD_URL = SERVER_LINK + "/user/discord";
const LOGIN_DISCORD_URL = SERVER_LINK + "/user/login-discord";

export const getUser = async (
    accessToken: string,
    setLogin: (accessToken: string, email: string) => void,
): Promise<GetUserResponse> => {
    try {
        const res = await authGet(GET_USER_URL, accessToken, setLogin);
        return mapUserDataToUser(res.data.data);
    } catch (err: any) {
        return Promise.reject(err.response.data.msg);
    }
};

export const updateUser = async (
    params: UpdateUserParams, 
    accessToken: string, 
    setLogin: (accessToken: string, email: string) => void,
): Promise<void> => {
    const body = mapUserToUserData(params);
    try {
        await authPut(UPDATE_USER_URL, body, accessToken, setLogin);
    } catch (err: any) {
        return Promise.reject(err.response.data.msg);
    }
};

export const updateEvents = async (
    params: UpdateEventParams,
    accessToken: string,
    setLogin: (accessToken: string, email: string) => void,
): Promise<void> => {
    try {
        await authPut(UPDATE_EVENTS_URL, params, accessToken, setLogin);
    } catch (err: any) {
        return Promise.reject(err.response.data.msg);
    }
};

export const verifyUser = async (
    params: VerifyUserParams,
    accessToken: string,
    setLogin: (accessToken: string, email: string) => void,
): Promise<void> => {
    const url = VERIFY_USER_URL + "?" + new URLSearchParams({ token: params.token });
    try {
        await authGet(url, accessToken, setLogin);
    } catch (err: any) {
        return Promise.reject(err.response.data.msg);
    }
};

export const loginDiscord = async (
    params: LoginDiscordParams,
    accessToken: string, 
    setLogin: (accessToken: string, email: string) => void,
): Promise<void> => {
    const url = LOGIN_DISCORD_URL + "?" + new URLSearchParams({ code: params.code });
    try {
        await authGet(url, accessToken, setLogin);
    } catch (err: any) {
        return Promise.reject(err.response.data.msg);
    }
}

export const unlinkDiscord = async (
    accessToken: string,
    setLogin: (accessToken: string, email: string) => void,
): Promise<void> => {
    try {
        await authDelete(UNLINK_DISCORD_URL, accessToken, setLogin);
    } catch (err: any) {
        return Promise.reject(err.response.data.msg);
    }
};
