import { Box, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useEventInfo } from "../../../../hooks/EventInfoProvider";
import DaySelector from "../../../../components/DaySelector";
import { EventDetails } from "../../../../types/types";

import schedule from "../../../../dataFiles/Schedule.json";

import { dateDiffInDays } from "../../../../utils/dateTimeHelpers";
import EventScheduleGrid from "./components/EventScheduleGrid";

interface EventLocationInfo {
    isVirtual : boolean;
    location : string;
}

export default function EventSchedule() {
    const eventInfo = useEventInfo();

    const [numDays, setNumDays] = useState(1);
    const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
    const [currDaySelected, setCurrDaySelected] = useState(0);
    const [currDay, setCurrDay] = useState(0);
    const eventSchedule = useRef<Array<Array<EventDetails>> | undefined>(
        undefined
    );

    const eventLocationSchedule = useRef<Array<EventLocationInfo> | undefined>(undefined);

    useEffect(() => {
        //sort event schedule by day and store

        const sortedSchedule = schedule.events.sort((event1, event2) =>
            event1.day < event2.day ? -1 : 1
        );

        eventSchedule.current = sortedSchedule.map((data) => data.events);
        eventLocationSchedule.current = sortedSchedule.map((data) => {
            return {
                isVirtual: data.eventIsVirtual,
                location: data.location
            };
          });

        // This is done so that the timeline will rerender after
        // the eventSchedule is initialized
        setNumDays(eventSchedule.current.length);
    }, []);

    useEffect(() => {
        //new date - current date
        const dateDiff = dateDiffInDays(
            eventInfo?.eventStartDateTime || new Date(),
            new Date()
        );
        setCurrDay(dateDiff);
        dateDiff < 0 ? setCurrDaySelected(0) : setCurrDaySelected(dateDiff);
    }, [eventInfo?.eventStartDateTime]);

    const renderTimeline = () => {
        return (
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                {[...Array(numDays)].map(
                    (ignore: number, index: number) => {
                        return (
                            <DaySelector
                                key={index}
                                currentDay={index + 1}
                                isDayPassed={index < currDay}
                                isSelected={index === currDaySelected}
                                isCurrDay={index === currDay}
                                hasPrevDay={index !== 0}
                                handleSelectDay={() => {
                                    setIsDescriptionVisible(true);
                                    setCurrDaySelected(index);
                                }}
                                daysLeftTillCurrDay={
                                    currDay <= 0
                                        ? -currDay + index
                                        : index - currDay
                                }
                            />
                        );
                    }
                )}
            </Box>
        );
    };

    const renderEventHeldVenue = () => {
        const eventLocationInfo =
            eventLocationSchedule.current &&
            eventLocationSchedule.current[currDaySelected];

        return (
            <Typography
                color="secondary"
                sx={{
                    typography: {
                        xs: "caption",
                        sm: "body1",
                        md: "h4",
                        lg: "h3",
                    },
                }}
            >
                <strong>
                    Event is
                    {eventLocationInfo?.isVirtual
                        ? " VIRTUAL"
                        : ` PHYSICAL at ${eventLocationInfo?.location || "TBD"}`}
                </strong>
            </Typography>
        );
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <Typography variant="h2TitleFont">Schedule</Typography>
            {schedule.confirmed ? (
                <>
                    <div style={{ marginTop: "1rem" }} />
                    {renderTimeline()}
                    {isDescriptionVisible && 
                        <>
                            <Box
                                sx={{
                                    marginTop: {
                                        xs: "2.6rem",
                                        sm: "2.6rem",
                                        md: "2.6rem",
                                        lg: "3.2rem",
                                    },
                                }}
                            >
                                {renderEventHeldVenue()}
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    marginTop: {
                                        xs: "2.3rem",
                                        sm: "2.3rem",
                                        md: "2.6rem",
                                        lg: "2.8rem",
                                    },
                                    width: "100%",
                                    justifyContent: "center",
                                }}
                            >
                                <EventScheduleGrid
                                    eventSchedule={
                                        eventSchedule.current &&
                                        eventSchedule.current[currDaySelected]
                                    }
                                />
                            </Box>
                        </>
                    }
                </>
            ): (
                <Typography variant="body1">
                    Coming soon! Stay tuned for more information.
                </Typography>
            )}
        </div>
    );
}
