import React from "react";

import "./Clouds.css";
import IMAGES from "../../assets/images";

interface Props {
    top: string;
    left: string;
}

export default function Clouds(props: Props) {
    // var animation_string = "rocketFlight " + props.timing.toString() + "s linear " + props.delay.toString() + "s infinite";

    var styles: { [key: string]: React.CSSProperties } = {
        cloud: {
            // animation: animation_string,
            // top: props.top,
            // left: props.left,
        },
    };

    return (
        <div>
            <div className="cloud-container" style={styles.cloud}>
                <img className="cloud-img" src={IMAGES["spaceWave"]} alt="space cloud wave" style={{width:"100%", maxHeight: "500px"}}/>
            </div>
        </div>
    );
}
