import React from "react";
import { Tooltip } from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import UserAvatar from "../../pages/Dashboard/components/UserInformation/components/UserAvatar";
import { User } from "../../types/types";
import TagChip from "../TagChip";
import "./styles.css";

type Props = {
    user: User;
    width?: number;
};

const IMG_HEIGHT = 1010;
const IMG_WIDTH = 1698;
const IMG_HEADER_HEIGHT = 210;
const IMG_CONTENT_HEIGHT = 670;
const IMG_FOOTER_HEIGHT = 130;

const ProfileCard = ({ user, width = 400 }: Props) => {
    const height = (IMG_HEIGHT * width) / IMG_WIDTH;
    const headerHeight = (height * IMG_HEADER_HEIGHT) / IMG_HEIGHT;
    const contentHeight = (height * IMG_CONTENT_HEIGHT) / IMG_HEIGHT;
    const footerHeight = (height * IMG_FOOTER_HEIGHT) / IMG_HEIGHT;
    const paddingWidth = width / 50;
    const {
        name,
        profilePicture,
        email,
        discordName,
        tags,
        isDiscordVerified,
    } = user;

    const header = (
        <div
            className="profile-card-header"
            style={{
                height: headerHeight,
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                color: "black",
                fontSize: 0.8 * headerHeight,
            }}
        >
            <strong>GAMECRAFT ID CARD</strong>
        </div>
    );

    const content = (
        <div
            className="profile-card-content"
            style={{
                height: contentHeight,
                display: "flex",
            }}
        >
            <div
                className="profile-picture"
                style={{
                    paddingLeft: paddingWidth * 2,
                    paddingRight: paddingWidth * 2,
                    paddingTop: contentHeight / 4,
                    paddingBottom: contentHeight / 4,
                }}
            >
                <UserAvatar
                    avatar={profilePicture}
                    width={contentHeight / 2}
                    isEditing={false}
                />
            </div>
            <div
                className="user-info"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginTop: "auto",
                    marginBottom: "auto",
                    height: "80%",
                    justifyContent: "space-evenly",
                    fontSize: 0.15 * contentHeight,
                }}
            >
                <div style={{ display: "flex" }}>
                    <div style={{ paddingRight: paddingWidth }}>NAME:</div>
                    {name}
                </div>

                <div style={{ display: "flex" }}>
                    <div style={{ paddingRight: paddingWidth }}>TAGS:</div>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {tags.map((tag) => (
                            <div
                                style={{ paddingRight: paddingWidth }}
                                key={tag}
                            >
                                <TagChip
                                    tag={tag}
                                    height={0.2 * contentHeight}
                                    font="retropix"
                                />
                            </div>
                        ))}
                    </div>
                </div>

                <div style={{ display: "flex" }}>
                    <div style={{ paddingRight: paddingWidth }}>DISCORD:</div>
                    {discordName}
                    <Tooltip
                        enterTouchDelay={0}
                        title={
                            isDiscordVerified
                                ? "Verified on Discord"
                                : "Pending Discord Verification"
                        }
                        sx={{ height: 0.2 * contentHeight }}
                    >
                        {isDiscordVerified ? (
                            <TaskAltIcon color="success" />
                        ) : (
                            <HelpOutlineIcon color="warning" />
                        )}
                    </Tooltip>
                </div>
            </div>
        </div>
    );

    const footer = (
        <div
            className="profile-card-footer"
            style={{
                height: footerHeight,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: paddingWidth,
                fontSize: 0.5 * footerHeight,
            }}
        >
            EMAIL: {email}
        </div>
    );

    return (
        <div
            className="profile-card"
            style={{
                width: width,
                height: height,
                margin: "auto",
            }}
        >
            {header}
            {content}
            {footer}
        </div>
    );
};

export default React.memo(ProfileCard);
