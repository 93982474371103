import React, { useState, useContext } from "react";
import EditableText from "../../../../../components/EditableText";
import { editTeamName } from "../../../../../apis/team";
import { ThemeContext } from "@mui/styled-engine";
import { LoginContext } from "../../../../../hooks/LoginProvider";

type Props = {
    isEditable: boolean;
    name: string;
    teamId: string;
};

const EditableTeamName = ({ isEditable, name, teamId }: Props) => {
    const theme: any = useContext(ThemeContext);
    const { accessToken, setLogin } = useContext(LoginContext);
    const [teamName, setTeamName] = useState<string>(name);

    const editName = async (teamName: string) => {
        try {
            await editTeamName({
                teamId: teamId, 
                teamName: teamName
            }, accessToken, setLogin);
            setTeamName(teamName);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <EditableText 
            name="Team name"
            text={teamName}
            onSave={editName}
            style={theme.typography.h2TitleFont}            
            isEditable={isEditable}
            validators={[{
                validatorFunction: (text: string) => text.length > 0, 
                errorMessage: "Team name must not be empty"
            }]}
        />
    );
};

export default React.memo(EditableTeamName);
