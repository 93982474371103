import React from 'react';
import { Typography } from "@mui/material";
import IMAGES from "../../../../assets/images";
import pastEvents from "../../../../dataFiles/PastEvents.json";
import "./PastEvents.css";

export default function PastEvents() {
    const renderAllImages = (pic: any, index: number) => {
        return (
            <div
                key={index}
                style={{
                    marginLeft: "25px",
                    marginRight: "25px",
                    marginBottom: "25px",
                }}
            >
                {renderImage(pic)}
            </div>
        );
    };

    const renderImage = (pic: any) => {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <div
                    className="images-container"
                >
                    <img
                        className="pastEventPic"
                        src={IMAGES[pic.path]}
                        alt={pic.name}
                        style={{ minWidth: "320px" }}
                    />
                </div>
            </div>
        );
    };

    return (
        <div
            style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignContent: "center"
            }}
        >
            <Typography variant="h2TitleFont" sx={{ marginBottom: "0.1em" }}>
                {pastEvents.titleText.title}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignContent: "center"
                    }}
                >   
                    <Typography variant="h4TitleFont" sx={{ marginBottom: "0.1em" }}>
                        {pastEvents.titleText.description}
                    </Typography>
                </div>
            </Typography>
            
            <div style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "start",
            }}
            >
                {pastEvents.pastEventPictures.map((pic, index) => {
                    return renderAllImages(pic, index);
                })}
            </div>
        </div>
    )
    
}