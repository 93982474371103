import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { EventInfoProvider } from "./hooks/EventInfoProvider";
import { ThemeProvider } from "@mui/material";
import mainTheme from "./constants/theme";

import IMAGES from "./assets/images";

import Navbar from "./components/NavBar/index";

import MainPage from "./pages/MainPage";
import RulesPage from "./pages/Rules";

import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import LoginProvider from "./hooks/LoginProvider";
import EventSchedule from "./pages/EventSchedule";
import JoinTeam from "./pages/JoinTeam";

import "./App.css";
import DiscordLogin from "./pages/DiscordLogin";

function App() {
  const location = useLocation();
  console.log(location.pathname);

  return (
    <div className="App customCursor" style={styles.customCursor}>
      <div style={styles.starBackground}>
        <ThemeProvider theme={mainTheme}>
          <EventInfoProvider>
            <LoginProvider>
              <>
                <Navbar />
                <Routes>
                  <Route path="/" element={<MainPage />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/schedule" element={<EventSchedule />} />
                  <Route path="/join/:teamId" element={<JoinTeam />} />
                  <Route path="/reset" element={<ResetPassword />} />
                  <Route path="/rules" element={<RulesPage />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/login-discord" element={<DiscordLogin />} />
                  <Route path="*" element={<MainPage />} />
                </Routes>
              </>
            </LoginProvider>
          </EventInfoProvider>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default App;

const styles: { [key: string]: React.CSSProperties } = {
  customCursor: {
    cursor: "url('" + IMAGES["ufo"] + "'), default",
  },
  starBackground: {
    // background image
    backgroundImage: 'url("' + IMAGES["stars"] + '")',
    backgroundRepeat: "repeat",
  }
};
