import { ConstructionRounded } from "@mui/icons-material";
import { EventDetails } from "../types/types";
import schedule from "../dataFiles/Schedule.json"
import eventInfo from "../dataFiles/EventInfo.json";

const _MS_PER_DAY = 1000 * 60 * 60 * 24;
export function dateDiffInDays(startDate: Date, endDate: Date): number {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

const EVENT_START_DATE = eventInfo.eventStartDate;
let deadlines: { [key: number]: Date } = {};
// Deadline to input the password for an event is 2 hours after the event ends
export function getEventPasswordDeadline(eventDay: number, event: EventDetails): Date {
    if (!deadlines.hasOwnProperty(event.eventId)) {
        const deadline = new Date(EVENT_START_DATE + " " + event.endTime);
        deadline.setDate(deadline.getDate() + eventDay)
        deadline.setHours(deadline.getHours() + 2);
        deadlines[event.eventId] = deadline;
    }

    return deadlines[event.eventId];
}

export const MONTH = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "September", "Oct", "Nov", "Dec"];
