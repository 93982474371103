import { Box, Typography, Button } from "@mui/material";
import React, { useState } from "react";
import UnlinkDiscordButton from "./UnlinkDiscordButton";


type Props = {
    discordName?: string
};

const CALLBACK_URL = encodeURIComponent(
    (process.env.NODE_ENV === 'production' ? "https://gamecraft.nusgdg.org" : "http://localhost:3000") 
    + "/login-discord"
);
const DISCORD_CLIENT_ID = "1063857802805788803";
const DISCORD_AUTH_URL = `https://discord.com/api/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&redirect_uri=${CALLBACK_URL}&response_type=code&scope=identify%20guilds.join`;

const DISCORD_COLOR = "#7289da";
const DISCORD_COLOR_HOVER = "#707af7";

const UserDiscord = ({ discordName }: Props) => {
    const [discord, setDiscord] = useState<string>(discordName || "");

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant="h4">Discord ID: </Typography>
            {discord ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Typography variant="body1">{discord}</Typography>
                    <UnlinkDiscordButton resetDiscord={() => setDiscord("")} />
                </div>
            ) : (
                <Button 
                    href={DISCORD_AUTH_URL} 
                    sx={{ 
                        backgroundColor: DISCORD_COLOR,
                        '&:hover': {
                            backgroundColor: DISCORD_COLOR_HOVER,
                        },
                    }}
                >
                    Login with Discord
                </Button>
            )}
        </Box>
    );
}

export default React.memo(UserDiscord);
