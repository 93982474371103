import React from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ExpandMore, HelpOutline } from "@mui/icons-material";
import SanitizeHtml from "../../../../../components/SanitizeHtml";
import { ThemeContext } from "@mui/styled-engine";

interface Props {
    question: string;
    answer: string;
}

function FaqAccordion(props: Props) {
    const theme: any = React.useContext(ThemeContext);

    return (
        <Accordion disableGutters={true} sx={styles.accordiantRoot}>
            <AccordionSummary
                expandIcon={
                    <ExpandMore sx={{ color: theme.palette.primary.main }} />
                }
                style={styles.accordianSummary}
            >
                <HelpOutline />
                <SanitizeHtml
                    dirtyHtml={props.question}
                    style={{
                        marginLeft: 10,
                        textAlign: "left",
                        fontFamily: theme.typography.fontFamily,
                        ...theme.typography.h5,
                    }}
                />
            </AccordionSummary>

            <AccordionDetails style={styles.accordianDetails}>
                <SanitizeHtml
                    dirtyHtml={props.answer}
                    style={{
                        marginLeft: 35,
                        textAlign: "left",
                        fontFamily: theme.typography.fontFamily,
                        ...theme.typography.body1,
                    }}
                />
            </AccordionDetails>
        </Accordion>
    );
}

const styles: { [key: string]: React.CSSProperties } = {
    accordiantRoot: {
        backgroundColor: "transparent",
        borderBottomColor: "white",
        borderBottomWidth: 1,
        boxShadow: "none",
    },
    accordianSummary: {
        color: "white",
    },
    accordianDetails: {
        color: "white",
        marginBottom: 10,
    },
};

export default React.memo(FaqAccordion);
