import { Grid } from "@mui/material";
import React from "react";
import profilePictures from "../../../../../constants/profilePictures";
import { ProfilePicture } from "../../../../../types/types";

type Props = {
    avatar: ProfilePicture;
    width?: number;
    height?: number;
    isEditing: boolean;
    setPicture?: (picture: ProfilePicture) => void;
};

const COLUMNS = 2;
const ROWS = Math.ceil(Object.values(ProfilePicture).length / COLUMNS);

const UserAvatar = ({ avatar, width = 200, isEditing, setPicture }: Props) => {
    if (!isEditing || !setPicture) {
        return (
            <img
                src={profilePictures[avatar]}
                style={{ 
                    width: width, 
                    height: width, 
                    margin: "auto", 
                }}
                alt="Avatar"
            />
        );
    }

    return (
        <Grid container spacing={0} sx={{ width: width, height: width, margin: "auto" }}>
            {Object.values(ProfilePicture).map((choice: ProfilePicture) => {
                const isSelected = avatar === choice;
                return (
                    <Grid xs={12 / COLUMNS} item key={choice}>
                        <img
                            src={profilePictures[choice]}
                            style={{ 
                                width: width / COLUMNS, 
                                height: width / ROWS, 
                                cursor: "pointer",
                                border: isSelected ? "1px solid white" : undefined,
                                borderRadius: 10,
                            }} 
                            alt={choice}
                            onClick={() => setPicture(choice)}
                        />
                    </Grid>
                );
            })}
        </Grid>
    )
};

export default React.memo(UserAvatar);
