import { TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { memo, useState } from "react";
import sha256 from "crypto-js/sha256";
import { updateEvents } from "../../../../apis/user";
import { useLogin } from "../../../../hooks/LoginProvider";
import schedule from "../../../../dataFiles/Schedule.json";
import { getEventPasswordDeadline } from "../../../../utils/dateTimeHelpers";

type Props = {
    eventList: Array<number>;
    refreshPage: () => Promise<void>;
    openSnackbar: (severity: "error" | "success", message: string) => void;
};

const INCORRECT_MESSAGE = "The password does not match any event!";
const ERROR_MESSAGE =
    "There was an error submitting the password. Please try again.";
const TOO_LATE_MESSAGE = (eventName: string) => {
    return "The deadline to submit the password for the event '" + eventName + "' has passed!"
}
const DUPLICATE_MESSAGE = (eventName: string) => {
    return "You've already submitted the password for the event '" + eventName + "'!";
}
const SUCCESS_MESSAGE = (eventName: string) => {
    return "You successfully submitted the password for the event '" + eventName + "'!";
}

const EventPasswordPanel = ({ eventList, refreshPage, openSnackbar }: Props) => {
    const { accessToken, setLogin } = useLogin();
    const [password, setPassword] = useState("");
    const [isSubmitting, setSubmitting] = useState(false);

    const eventSchedule = schedule.events
        .map((data) => data.events)
        .map((eventDay) => eventDay.filter((event) => event.passwordHash !== ""))

    const submitPassword = async () => {
        setSubmitting(true);

        const passwordHash = sha256(password).toString();
        const currDate = new Date();

        for (let i = 0; i < eventSchedule.length; i++) {
            for (const event of eventSchedule[i]) {
                if (passwordHash !== event.passwordHash) {
                    continue;
                }

                if (eventList.includes(event.eventId)) {
                    openSnackbar("error", DUPLICATE_MESSAGE(event.eventName));
                } else if (currDate > getEventPasswordDeadline(i, event)) {
                    openSnackbar("error", TOO_LATE_MESSAGE(event.eventName));
                } else {
                    try {
                        await updateEvents({ eventId: event.eventId }, accessToken, setLogin);
                        openSnackbar("success", SUCCESS_MESSAGE(event.eventName));
                        await refreshPage();
                    } catch (e) {
                        openSnackbar("error", ERROR_MESSAGE);
                        console.log(e);
                    }
                }

                setSubmitting(false);
                return;
            }
        }

        openSnackbar("error", INCORRECT_MESSAGE);
        setSubmitting(false);
    }

    return (
        <div style={{ border: "1px solid white", borderRadius: 10, padding: 16 }}>
            <Typography variant="h3TitleFont">
                <u className="header-profile">LUCKY DRAW EVENT PASSWORD</u>
            </Typography>

            <div className="event-password">
                <TextField
                    label="Input Event Password"
                    type="password"
                    onChange={(event) => setPassword(event.target.value)}
                    variant="outlined"
                    value={password}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            "& > fieldset": {
                                borderColor: "white",
                                borderRadius: "10px",
                            },
                        },
                    }}
                    style={{
                        marginTop: "5px",
                        marginRight: "20px",
                        marginBottom: "10px",
                    }}
                />

                <LoadingButton
                    variant="contained"
                    color="secondary"
                    loading={isSubmitting}
                    onClick={submitPassword}
                >
                    Submit
                </LoadingButton>
            </div>
        </div>
    );
};

export default memo(EventPasswordPanel);
