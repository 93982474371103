const IMAGES: Record<string, any> = {
    // Logos
    "gamecraft": require("./images/gamecraftLogo.png"),
    "gamecraftIcon": require("./images/gamecraftIcon.png"),
    "gamecraftIconWhite": require("./images/gamecraftIconWhite.png"),

    // Decorative
    "alien1": require("./images/alien1.png"),
    "alien2": require("./images/alien2.png"),
    "alien3": require("./images/alien3.png"),

    "astronaut": require("./images/astronaut.png"),

    "rocket": require("./images/rocket.png"),

    "planet1": require("./images/planet_1.PNG"),
    "planet2": require("./images/planet_2.PNG"),
    "planet3": require("./images/planet_3.PNG"),
    "planet4": require("./images/planet_4.PNG"),
    "planet5": require("./images/planet_5.PNG"),
    "planet6": require("./images/planet_6.PNG"),
    "planet7": require("./images/planet_7.PNG"),

    "moon": require("./images/moon.png"),


    "spaceWave": require("./images/spaceWave.png"),

    "trophy": require("./images/trophy.png"),
    "medal": require("./images/medal.png"),

    "moon2": require("./images/moon2.png"),

    "ufo": require("./images/ufo.png"),
    "ufo2": require("./images/ufo2.png"),

    "ufo1body": require("./images/ufo1body.png"),
    "ufo1glow": require("./images/ufo1glow.png"),


    "room": require("./images/room.png"),

    // Background imgs
    "stars": require("./images/stars.png"),

    // Past Events
    "2022Pic1": require("./images/pastEvents/IMG_9359.jpg"),
    "2022Pic2": require("./images/pastEvents/IMG_9363.jpg"),
    "2022Pic3": require("./images/pastEvents/IMG_9365.jpg"),
    "2022Pic4": require("./images/pastEvents/IMG_9381.jpg"),
    "2022Pic5": require("./images/pastEvents/IMG_9435.jpg"),
    "2022Pic6": require("./images/pastEvents/IMG_9495.jpg"),
    "2022Pic7": require("./images/pastEvents/photo_2_2022-12-10_15-33-04.jpg"),
    "2022Pic8": require("./images/pastEvents/photo_7_2022-12-10_15-33-04.jpg"),
    "2022Pic9": require("./images/pastEvents/photo_18_2022-12-10_15-33-04.jpg"),


    // Past Jams
    "sliggy_slime": require("./images/pastjams/sliggy_slime.png"),
    "cogsworks": require("./images/pastjams/cogworks_cover.png"),
    "kitten_mitten": require("./images/pastjams/kitten_mitten_cover.png"),
    "memory_lanes": require("./images/pastjams/memory_lanes_cover.png"),
    "A2wJky66rH": require("./images/pastjams/A2wJky66rH_cover.png"),
    "runic_conjecture": require("./images/pastjams/runic-conjecture.png"),

    "profile_card_bg": require("./images/profile_card_bg.png"),

    //sponsor banners
    "koei_banner": require("./images/sponsors/koei_Banner.png"),
    "century_games_banner": require("./images/sponsors/century_games_banner.jpg"),
    "soc_banner": require("./images/sponsors/nus_soc_banner.png"),

    //sponsor logos
    "nusLogo": require("./images/sponsors/nusLogo.png"),
    "gentlebrosLogo": require("./images/sponsors/gentlebrosLogo.png"),
    "koeiTecmoLogo": require("./images/sponsors/KoeiTecmoLogo.png"),
    "riotGamesLogo": require("./images/sponsors/riotGamesLogo.png"),
    "centuryGamesLogo": require("./images/sponsors/centuryGamesLogo.png"),
    "battlebrewLogo": require("./images/sponsors/BattlebrewProductionLogo.webp"),
    "magicblocksLogo": require("./images/sponsors/magicBlocksLogo.png"),

    //judges/speakers
    "kennyRiot": require("./images/guestsPictures/riot_kenny.jpeg"),
    "kennethCenturyGames": require("./images/guestsPictures/century_games_kenneth.jpg"),
    "antoineRiot": require("./images/guestsPictures/riot_antoine.jpg"),
    "alexNus": require("./images/guestsPictures/nus_alex.jpg"),
    "nusgdg": require("./images/guestsPictures/NUSGDG_LOGO.png"),
    "euniceSonySanta": require("./images/guestsPictures/santa_eunice.jpg"),
    "battleLydia": require("./images/guestsPictures/battlebrew_lydia.jpg"),
    "KangSoon": require("./images/guestsPictures/KangSoon.jpg"),
    "koeiZihao": require("./images/guestsPictures/koeiZihao.jpg"),
    "kokTungCenturyGames": require("./images/guestsPictures/century_games_kok_tung.jfif"),
    "shenXiangCenturyGames": require("./images/guestsPictures/century_games_xiang_shen.jpg"),
    "magicblockGabriele": require("./images/guestsPictures/magicblock_gabriele.jpg")
}

export default IMAGES;